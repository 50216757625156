
<template>
  <config-item :label="label">
    <GoodsConfigList :value="mValue" @edit="edit"></GoodsConfigList>
    <GoodsConfigChoose v-if="show" :show.sync="show" :value="mValue" @submit="replace"></GoodsConfigChoose>
  </config-item>
</template>

<script>
import ConfigItem from '../../../components/global/ConfigItem.vue'
import schemaMixin from '@/mixin/schemaMixin'
import { mapGetters } from "vuex";

export default {
  name: 'SchemaGoods',

  components: { ConfigItem },

  mixins: [schemaMixin],

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      show: false,
      mValue: []
    }
  },

  computed: {
    ...mapGetters(["curPage"]),
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.mValue = newValue
      }
    },

    mValue: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.$emit('input', newValue)
      }
    },
    "curPage.componentList": {
      handler(val) {
        // console.log('watch1', val)
        // this.messageList();
      },
      deep: true,
    },
  },

  methods: {
    edit(item) {
      this.show = true
    },

    replace(ids) {
      this.mValue = ids
    }
  }
}
</script>

<style lang="scss" scoped></style>

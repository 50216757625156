<!--标题组件style_11-->
<template>
    <div class="title_com" :style="[mcTitle.wrapStyle()]">
        <div class="title-left-model" :style="[mcTitle.comStyle()]">
            <img class="left-img" src="~@/assets/image/style_select/t3_bg1.png" alt="">
            <img class="right-img" src="~@/assets/image/style_select/t3_bg2.png" alt="">
            <!-- <img class="ti_img" src="" alt="~@/assets/image/style_select/title3.png"> -->
            <!-- <div class="ti-right"> -->
            <div class="title bold" :style="[mcTitle.titleStyle()]">
                {{ mcTitle.value.title || '' }}
            </div>
            <div class="info" :style="[mcTitle.subTitleStyle()]">{{ mcTitle.value.info || '' }}</div>
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'TitleStyle_11',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title_com {
    box-sizing: content-box;

    .ti_img {
        margin-left: 12px;
        max-width: 40px;
        max-height: 40px;
    }

    .ti-right {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        justify-content: space-between;

    }

    .title-left-model {
        display: flex;
        padding-left: 25px;
        height: 60px;
        position: relative;
        flex-direction: column;
        justify-content: center;

        // justify-content: space-between;
        .info {
            // padding-left: 8px;
            padding-top: 7px;
            position: relative;
            z-index: 1;
        }

        .title {
            font-size: 16px !important;
            position: relative;
            z-index: 1;
            // padding: 0 8px;

            &.bold {
                font-weight: 700;
            }
        }

        // // align-items: flex-end;
        // padding-left: 10px;
        // padding-right: 10px;
        .right-img {
            width: 17.5px;
            position: absolute;
            top: 1px;
            left: 70px;
        }

        .left-img {
            width: 30.5px;
            position: absolute;
            bottom: -11px;
            left: 4px;
            // bottom: -30px;
            // left: -14px;
        }
    }

}

.title-mid-model {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>

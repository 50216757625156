
<template>
  <div class="flex flex-wrap">
    <div v-for="(item, key) in list" class="flex-center h45 w45 mr10 mb10 shadow">
      <img class="w-100 h-100" :src="item.cover" />
    </div>
    <div class="flex-center h45 w45 shadow pointer" @click="add">
      +
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getGoodsListByIds } from "@/api/goods";

export default {
  name: "GoodsConfigList",
  props: ["value"],

  data() {
    return {
      mValue: [],
      list: [],
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.getList();
      },
    },
  },

  methods: {
    // 获取商品列表
    async getList() {
      let data = {
        // projectId: this.project.id,
        shopID:this.project.MartID, 
        productType: 8,
        productMainIDs: this.value,
      };
      const { list, status } = await getGoodsListByIds(data);
      if (status == 10000) {
        this.list = list;
      }
    },

    add() {
      this.$emit("edit");
    },
  },
};
</script>

<!--图片组件-->
<template>
  <div :style="[$wrapStyle(imageStyle), $cmpStyle(this.imageStyle)]">
    <el-image class="img" :style="[getImgStyle()]" :src="(imageValue && imageValue.imagePath) || defaultImage" />
  </div>
</template>

<script>
import defaultValue from '@/assets/image/default_otherGoods.jpg'
export default {
  name: "McImg",

  props: {
    space: {
      type: Object,
      default: () => { },
    },
    radius: {
      type: Object,
      default: () => { },
    },
    imageValue: {
      type: Object,
      default: () => { },
    },
    color: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      defaultImage: defaultValue,
    };
  },
  computed: {
    imageStyle() {
      return { ...this.space, ...this.color }
    },
  },
  methods: {
    getImgStyle() {
      return {
        borderRadius: `${this.radius?.cmpUpperRadius}px ${this.radius?.cmpUpperRadius}px ${this.radius?.cmpLowerRadius}px ${this.radius?.cmpLowerRadius}px`
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
}
</style>

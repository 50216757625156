<template>
  <div class="wrap">

    <div class="wrap-label">
      <span>{{ label }}</span>
    </div>

    <div class="wrap-body">
      <div @click="handleOpen" class="body-content">
        <img :src="mValue.imagePath || defaultValue" alt="" style="width: 100%;" ref="imgRef"
          @load="getImageNaturalWidth">
        <div class="hotSpotItem" v-for="item of mValue.HotspotList" :style="getItemStyle(item)">
          <div class="setting">{{ item.jump?.name || '设置热区' }}</div>
        </div>
        <p class="replace" @click.stop="handleSelectFile">替换</p>
      </div>
    </div>
    <Set ref="setRef" @change="handleChange" :url="mValue.imagePath" :list="mValue.HotspotList" />
    <UploadDialog ref="uploadDiaRef" @select="handleSelect" />
  </div>
</template>

<script>
import defaultValue from '@/assets/image/default_banner.png'
import schemaMixin from "@/mixin/schemaMixin";
import Set from './set.vue'
import UploadDialog from '@/components/UploadDialog'
export default {
  name: "SchemaHot",
  mixins: [schemaMixin],
  props: {
    label: {
      type: String,
      default: "",
    },
    line: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Set,
    UploadDialog
  },
  data() {
    return {
      defaultValue
    };
  },
  methods: {
    getImageNaturalWidth() {
      this.mValue.naturalWidth = this.$refs['imgRef'].naturalWidth;
    },
    handleChange(list) {
      this.mValue.HotspotList = this.$cloneDeep(list)
    },
    handleSelect(list) {
      this.mValue.imagePath = list[0]
    },
    /**打开文件选择弹框 */
    handleSelectFile() {
      this.$nextTick(() => {
        this.$refs["uploadDiaRef"].open()
      })
    },
    getItemStyle(item) {
      return {
        position: 'absolute',
        zIndex: `${item.index}`,
        top: this.$scale(item.top, 412, 750),
        left: this.$scale(item.left, 412, 750),
        width: this.$scale(item.width, 412, 750),
        height: this.$scale(item.height, 412, 750)
      }
    },
    handleOpen() {
      this.$nextTick(() => {
        this.$refs.setRef.open()
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background: #fff;

  .wrap-label {
    padding: 10px 12px;
    background: #e8f0fb40;
    font-size: 14px;
    color: #323233;
    font-weight: 550;

    span {
      &::before {
        content: ".";
        width: 3px;
        height: 10px;
        margin-right: 8px;
        background: $color-theme;
      }
    }
  }

  .wrap-body {
    padding: 10px 20px 30px 20px;
    user-select: none;

    .body-content {
      border: 1px solid rgb(237, 237, 237);
      border-radius: 4px 4px 0 0;
      text-align: center;
      min-height: 60px;
      position: relative;
    }

    .replace {
      left: -1px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      position: absolute;
      bottom: -30PX;
      right: -1px;
      text-align: center;
      background: rgba(0, 0, 0, .3);
      color: #fff;
      cursor: pointer;
      z-index: 99;
    }

    .hotSpotItem {
      background: #fee69c;
      opacity: .8;
      border: 1px solid var(--primary, #fb6638);
      color: var(--primary, #fb6638);
      display: flex;
      align-items: center;
      justify-content: center;

      .setting {
        max-height: 100%;
        overflow: hidden;
        font-size: 12px;
      }
    }

    .wrap-line {
      margin-top: 30px;
      height: 1px;
      background: #ebedf0;
    }
  }
}
</style>
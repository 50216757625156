<!--标题组件-->
<template>
	<div :style="[getWrapStyle({ ...color, ...space })]">
		<div class="pictures-group" :style="[getGroupStyles({ ...space, ...attrs })]">
			<div class="pictures-col" v-for="(item, index) of list" :key="index">
				<div class="img" :style="[getImgStyle(item)]">
				</div>
				<p class="title ellipsis-1" :style="[titleStyle()]"
					style="overflow: hidden; color: rgb(255, 255, 255); border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;">
					{{ item.title }}</p>
				<p class="subtitle ellipsis-1" :style="[subTitleStyle()]">{{ item.label }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import default_picture from '@/assets/image/default_picture.png'
export default {
	name: 'McPictures',

	props: {
		attrs: {
			type: Object,
			default: () => { }
		},
		value: {
			type: Object,
			default: () => { }
		},
		space: {
			type: Object,
			default: () => { }
		},
		radius: {
			type: Object,
			default: () => { }
		},
		color: {
			type: Object,
			default: () => {
				return {
					cmpBackground: "",
					infoColor: "#999999",
					titleColor: "#333333",
				}
			}
		},

		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		styles() {
			return { ...this.space, ...this.radius, ...this.color }
		}
	},
	methods: {
		getGroupStyles(item) {
			return {
				textAlign: item.itemAlign,
				margin: `${item.cmpUpperPadding}px ${item.pagePadding}px ${item.cmpLowerPadding}px ${item.pagePadding}px`
			}
		},
		getWrapStyle(item) {
			return {
				// width: '100%',
				overflow: 'hidden',
				background: item.cmpBackground,
				// marginTop: `${this.space.MarginTop}px`
				margin: `${this.space.MarginTop}px ${this.space.pageMargin}px 0`
			}
		},
		getImgStyle(item) {
			return {
				overflow: 'hidden',
				borderRadius: `${this.radius.cmpUpperRadius}px ${this.radius.cmpUpperRadius}px ${this.radius.cmpLowerRadius}px ${this.radius.cmpLowerRadius}px`,
				backgroundImage: `url('${item.image || default_picture}')`
			}
		},
		baseStyle() {
			return this.$baseStyle(this.styles)
		},
		// 主标题样式
		titleStyle() {
			return {
				color: this.color.titleColor,
				borderRadius: `${this.radius.cmpUpperRadius}px ${this.radius.cmpUpperRadius}px ${this.radius.cmpLowerRadius}px ${this.radius.cmpLowerRadius}px`,
			}
		},
		// 副标题样式
		subTitleStyle() {
			return {
				color: this.color.infoColor,
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.pictures-group {
	width: 99999999999px;
	overflow: hidden;
	display: flex;

	.pictures-col {
		position: relative;
		width: 128px;
		margin-right: 12px;
		font-size: 13px;

		.title {
			position: absolute;
			top: 103px;
			height: 25px;
			line-height: 25px;
			width: 100%;
			background: linear-gradient(180deg, transparent, #9f9f9f) !important;
			color: #fff;
			padding: 0 6px;
			font-size: 14px;
			font-weight: bolder;
			text-overflow: ellipsis;
			box-sizing: border-box;
		}

		.subtitle {
			height: 25px;
			line-height: 25px;
			width: 100%;
			color: #333;
			padding: 0 6px;
			font-size: 13px;
		}
	}

	.img {
		width: 128px;
		height: 128px;
		display: block;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
	}
}

.title {
	box-sizing: content-box;

	.title-left-model {
		display: flex;
		align-items: flex-end;
		padding-left: 10px;
		padding-right: 10px;
	}

	.title-mid-model {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
</style>

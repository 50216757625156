
<template>
  <el-dialog title="请选择跳转页面" :visible.sync="show" width="30%" :modal="modal">
    <el-form label-position="top" label-width="80px" class="jump-form">
      <el-form-item label="链接">
        <el-select v-model="value.type" placeholder="请选择要跳转链接" style="width:100%" @change="handleChange">
          <el-option v-for="(page, i) in fixedPages" :key="i" :label="page.Title" :value="page.NavigateType">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="参数">
        <el-select v-model="value.id" placeholder="请选择要跳转参数" :disabled="!value.type" style="width:100%">
          <el-option v-for="(page, i) in moduleList" :key="i" :label="page.Name" :value="page.CategoryID">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer ">
      <el-button round style="width: 80px" @click="show = false">取消</el-button>
      <el-button type="primary" round style="width: 80px" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getCategories } from '@/api/project.js'

export default {
  name: "JumpDialog",
  // inject: ["value"],
  props: {
    modal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      moduleList: [],
      value: {}
    };
  },
  computed: {
    ...mapGetters(["project", "fixedPages"]),

  },
  methods: {
    submit() {
      this.show = false
      // const _type = this.fixedPages?.find(f => f.NavigateType == this.value.type)?.Title || '-'
      const _Id = this.moduleList?.find(f => f.CategoryID == this.value.id)?.Name || '-'
      this.value.name = `${_Id}`
      this.$emit('ok', this.value)
    },
    handleChange(e, once) {
      const params = {
        type: e
      }
      getCategories(params).then(res => {
        this.moduleList = res || []
        if (!once) {
          this.value.id = ''
        }
      })
    },
    open(value) {
      this.show = true;
      this.value = { ...value, id: value.id * 1 }
      this.$nextTick(() => {
        console.log(value)
        if (this.value && this.value.type) {
          this.handleChange(this.value.type, true)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.jump-form {

  .el-form-item {
    margin-bottom: 10px;
  }
}

.tabs {
  display: flex;

  el-form-item .tab {
    width: 100px;
    height: 30px;
    color: #979797;
    border: solid 1px #ebedf0;
    cursor: pointer;
  }

  .tab-active {
    color: $color-theme;
    border: solid 1px $color-theme;
    background: #e0edff;
  }
}
</style>
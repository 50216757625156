/*
 * @Description: 项目管理api

 * @Date: 2021-09-22 15:51:56

 * @LastEditTime: 2022-02-10 20:15:44
 */
import request from '@/utils/request'

/**
 * 获取微商城列表
 * @param {*} data 
 * @returns 
 */
export const getTempMall = (data) => {
  return request({
    url: '/project/getTempMall',
    method: 'POST',
    data
  })
}
/**
 * 编辑页面配置
 * @param {*} data 
 * @returns 
 */
export const updateThemePageWidgets = (data) => {
  return request({
    url: '/project/updateThemePageWidgets',
    method: 'POST',
    data
  })
}
/**
 * 获取页面的配置信息
 * @param {*} params 
 * @returns 
 */
export const getThemePageWidgets = (params) => {
  return request({
    url: `/project/getThemePageWidgets?shopID=${params.shopID}&pageID=${params.pageID}`,
    method: 'GET',
  })
}
/**
 * 获取商家模版信息
 * @param {*} params 
 * @returns 
 */
export const getPageTemplateWidgets = (data) => {
  return request({
    url: `/project/getPageTemplateWidgets?shopID=${data.shopID}`,
    method: 'post',
    data
  })
}
/**
 * 页面封面
 * @param {*} data 
 * @returns 
 */
export const updateTemplateCover = (data) => {
  return request({
    url: `/project/updateTemplateCover?cover=${data.cover}`,
    method: 'post',
    data
  })
}
/**
 * 设置页面主页
 * @param {*} params 
 * @returns 
 */
export const setHomePage = (params) => {
  return request({
    url: `/project/setHomePage?shopID=${params.shopID}&pageID=${params.pageID}`,
    method: 'GET',
  })
}
/**
 * 获取商城页面
 * @param {*} data 
 * @returns 
 */
export const getWidgetPages = (shopID, data) => {
  return request({
    url: '/project/getWidgetPages?shopID=' + shopID,
    method: 'POST',
    data
  })
}

/**
 * 查询项目列表
 */
export const getProjectList = async data =>
  request({ url: '/project/getByList', method: 'POST', data })
/**
 * 新增页面
 */
export const addWidgetPage = async data =>
  request({ url: '/project/addWidgetPage', method: 'POST', data })
/**
 * 查询项目列表
 */
export const getGetTempMarts = async data =>
  request({ url: '/project/getGetTempMarts', method: 'POST', data })

/**
 * 根据id查询项目详情
 */
export const getProjectById = async data =>
  request({ url: '/project/getById', method: 'POST', data })

/**
 * 新增项目
 */
export const addProject = async data =>
  request({ url: '/project/add', method: 'POST', data })

/**
 * 编辑
//  */
// export const editProject = async data =>
//   request({ url: '/project/edit', method: 'POST', data })

/**
 * 删除
 */
export const delProject = async data =>
  request({ url: '/project/delete', method: 'POST', data })

/**
 * 查询项目模板
 */
export const getModelList = async data =>
  request({ url: '/project/getGetTempMarts', method: 'POST', data })

/**
 * 封面上传
 */
export const uploadCover = async data =>
  request({ url: '/upload', method: 'POST', data })

/**
 * 生成小程序码
 */
export const createWXcode = async data =>
  request({ url: '/project/getWXQr', method: 'POST', data })

/**
 * 获取模块列表
 * @param {*} params 
 * @returns 
 */
export const getNavigates = (params) => {
  return request({
    url: '/goods/getNavigates',
    method: 'get',
    params
  })
}

export const getCategories = (params) => {
  return request({
    url: '/goods/getCategories',
    method: 'get',
    params
  })
}
/**
 * 删除page页面
 * @param {*} params 
 * @returns 
 */
export const deleteWidgetPage = (params) => {
  return request({
    url: '/project/deleteWidgetPage',
    method: 'get',
    params
  })
}
/**
 * 获取商家条信息
 * @param {*} params 
 * @returns 
 */
export const getShopInfo = (params) => {
  return request({
    url: `/project/getShopInfo?shopID=${params.shopID}`,
    method: 'get',
    params
  })
}
import request from '@/utils/request'
/**
 * 查询商品列表
 */
export const getGoodsList = async (data) => request({ url: "/goods/getByList", method: "POST", data })

/**
 * 根据id查询商品详情
 */
export const getGoodsById = async (data) => request({ url: "/goods/getById", method: "POST", data })

/**
 * 根据ids查询商品列表
 */
export const getGoodsListByIds = data => request({ url: '/goods/getByIds', method: 'post', data })
/**
 * 获取行业
 */
export const getShopIndustries = data => request({ url: '/goods/getShopIndustries', method: 'post', data })
/**
 * 添加模块
 */
export const addShopTemplate = data => request({ url: '/goods/addShopTemplate', method: 'post', data })

/**
 * 查询商品图片列表
 * @param {*} params 
 * @returns 
 */
export const getFilePage = (data) => {
    return request({
        url: '/goods/getFilePage',
        method: 'POST',
        data
    })
}
/**
 * 查询商品视频列表
 * @param {*} data 
 * @returns 
 */
export const getFileVideoPage = (data) => {
    return request({
        url: '/goods/getFileVideoPage',
        method: 'POST',
        data
    })
}
/**
 * 获取商品特征
 * @param {*} data 
 * @returns 
 */
export const getProductFeature = (data) => {
    return request({
        url: '/goods/getProductFeature',
        method: 'get',
        data
    })
}
/**
 * 获取商品清单
 * @param {*} data 
 * @returns 
 */
export const getRecommentdList = (params) => {
    return request({
        url: '/goods/getRecommentdList',
        method: 'get',
        params
    })
}
/**
 * 获取活动
 * @param {*} data 
 * @returns 
 */
export const getPromotions = (params) => {
    return request({
        url: '/goods/getPromotions',
        method: 'get',
        params
    })
}

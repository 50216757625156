<!--标题组件style_6-->
<template>
    <div :style="[mcTitle.wrapStyle()]">
        <div :style="[mcTitle.comStyle()]">
            <div class="title-com title1">
                <div class="title-outer" :style="{ borderColor: mcTitle.color.titleColor }">
                    <span class=" line left" :style="{ background: mcTitle.color.titleColor }"></span>
                    <span class="inner-line" :style="{ borderColor: mcTitle.color.titleColor }"></span>
                    <span class="title" :style="[mcTitle.titleStyle()]">{{ mcTitle.value.title }}</span>
                    <span class="line right" :style="{ background: mcTitle.color.titleColor }"></span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'TitleStyle_6',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title-com {
    margin: 5px;
    font-size: 16px;
    text-align: center;

    .title-outer {
        // position: relative;
        // display: flex;
        // align-items: center;
        // width: 100%;
        // justify-content: center;
        position: relative;
        display: inline-block;
        border: 1px solid #000;

        .inner-line {
            position: absolute;
            top: -5px;
            right: -5px;
            bottom: -5px;
            left: -5px;
            border: 1px solid #000;
            z-index: 0;
        }

        .title {
            height: 28px;
            line-height: 28px;
            padding: 0 10px;
            margin: 0 5px;
            z-index: 19;
            position: relative;
            display: inline-block;
            font-size: 16px !important;
        }

        .line {
            height: 5px;
            position: absolute;
            width: 40px;
            top: 50%;
            background: #000;
            margin-top: -2px;

            &.left {
                left: 0;
                margin-left: -35px;
            }

            &.right {
                right: 0;
                margin-right: -35px;
            }
        }
    }

}
</style>

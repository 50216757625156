<!--标题组件-->
<template>
	<div :style="[getWrapStyle({ ...color, ...radius, ...space })]">
		<div class="goods-group template1-otherGoods" :style="[getGroupStyles({ ...space, ...attrs, ...color })]">
			<div class="background">
				<p class="title" :style="[titleStyle()]">
					{{ imageValue.title }}</p>
				<p class="subtitle" :style="[subTitleStyle()]">
					{{ imageValue.label }}</p>
				<div class="img" :style="[getImgStyle()]"></div>
			</div>
			<div class="list">
				<div class="goods-item" v-for="(item, index) of list" :key="index">
					<div class="image">
						<div class="img" :style="getItemImgStyle(item)"></div>
					</div>
					<div class="detail">
						<div class="name ellipsis-1"> {{ item.title }}</div>
						<div class="price "><span class="text ellipsis-1">{{ item.label }}</span></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import default_picture from '@/assets/image/default_picture.png'
import default_otherGoods from '@/assets/image/default_otherGoods.jpg'
export default {
	name: 'McPicturesAdvanced',

	props: {
		list: {
			type: Array,
			default: () => [],
		},
		imageValue: {
			type: Object,
			default: () => { }
		},
		attrs: {
			type: Object,
			default: () => { }
		},
		value: {
			type: Object,
			default: () => { }
		},
		space: {
			type: Object,
			default: () => { }
		},
		radius: {
			type: Object,
			default: () => { }
		},
		color: {
			type: Object,
			default: () => { }
		},
	},
	computed: {
		styles() {
			return { ...this.space, ...this.radius, ...this.color }
		}
	},
	methods: {
		getWrapStyle(item) {
			return {
				// width: '100%',
				overflow: 'hidden',
				background: item.cmpBackground,
				borderRadius: `${this.radius.cmpUpperRadius}px ${this.radius.cmpUpperRadius}px ${this.radius.cmpLowerRadius}px ${this.radius.cmpLowerRadius}px`,
				// marginTop: `${this.space.MarginTop}px`
				margin: `${this.space.MarginTop}px ${this.space.pageMargin}px 0`
			}
		},
		getGroupStyles(item) {
			return {
				borderColor: 'rgb(237, 237, 237)',
				textAlign: 'left',
				margin: `${item.cmpUpperMargin}px ${item.pagePadding}px ${item.cmpLowerMargin}px`,
				background: item.bottomBackground
			}
		},
		getImgStyle() {
			return {
				backgroundImage: `url('${this.imageValue.imagePath || default_otherGoods}')`
			}
		},
		getItemImgStyle(item) {
			return {
				backgroundImage: `url('${item.image || default_picture}')`
			}
		},
		baseStyle() {
			return this.$baseStyle(this.styles)
		},
		// 主标题样式
		titleStyle() {
			return {
				color: this.color.titleColor,
			}
		},
		// 副标题样式
		subTitleStyle() {
			return {
				color: this.color.infoColor,
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.goods-group {
	position: relative;
	height: 300px;
	overflow: hidden;
	box-sizing: border-box;
	margin: 12px;

	.list {
		position: absolute;
		top: 115px;
		left: 12px;
		right: 12px;
		box-shadow: 0 0 5px #f0f1f5;
		// border-radius: 5px 0 0 5px;
		border-radius: 5px;
		background: #fff;
		overflow: hidden;
		display: flex;
	}

	.goods-item {
		width: 90px;
		flex-direction: column;
		text-align: center;
		float: left;
		padding: 14px 8px;
		box-sizing: content-box;
		position: relative;
		height: auto;
		display: flex;

		.detail {
			position: relative;
			overflow: hidden;
			// padding: 8px 12px;
			box-sizing: border-box;

			.name {
				font-size: 14px;
				line-height: 18px;
				max-height: 36px;
				margin-bottom: 4px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				white-space: nowrap;
				margin-top: 10px;
				color: #202021;
				font-size: 14px;
				font-weight: 700;
			}
		}

		.price {
			.text {
				font-size: 13px;
				line-height: 1;
				margin-top: 10px px;
				font-weight: 400;
				color: #999ca7;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	.image {
		width: 90px !important;
		height: 90px !important;
		position: relative;
		display: block;
		max-height: 500px;
		background-size: cover;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: 50%;

		.img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: cover;
		}
	}
}

.background {
	width: 100%;
	height: 145.5px;
	padding-top: 26px;
	padding-left: 25px;
	position: relative;

	p {
		position: relative;
		z-index: 1;
	}

	.title {
		font-size: 20px;
		color: #242837;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 10px;
	}

	.subtitle {
		font-size: 12px;
		line-height: 1;
		color: #2f3033;
		margin-bottom: 10px;
	}

	.img {
		width: 100%;
		position: absolute;
		top: 0;
		height: 145.5px;
		left: 0;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
	}
}
</style>

<!--标题组件style_3-->
<template>
    <div :style="[mcTitle.wrapStyle()]">
        <div :style="[mcTitle.comStyle()]">
            <div class="title-com title3">
                <div class="title-outer" style="border-color: rgb(51, 51, 51);">
                    <!-- <span class="line" :style="{ background: mcTitle.color.titleColor }"></span> -->
                    <span class="title bold" :style="[mcTitle.titleStyle()]">{{ mcTitle.value.title }}</span>
                    <span class="inner-line" :style="{ background: mcTitle.color.titleColor }"></span>
                    <span class="line" :style="{ borderBottomColor: mcTitle.color.titleColor }"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleStyle_3',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title-com {
    margin: 5px;
    font-size: 16px;
    text-align: center;

    &.title3 {
        .title {
            font-size: 16px !important;
            padding-bottom: 4px;
        }

        .line {
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            /* 左边框 */
            border-right: 9px solid transparent;
            /* 右边框 */
            border-bottom: 12px solid red;
            /* 底边框 */
            background: transparent !important;
            transform: rotate(180deg);
        }
    }

    .title-outer {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-direction: column;

        .inner-line {
            width: 100%;
            height: 1px;
            background: #000;
        }
    }

}
</style>


<template>
  <el-dialog title="风格选择器" class="style-select-dia" :visible.sync="show" top="10vh" width="810px">
    <div class="preview">
      <div class="preview-content">
        <div class="content_item" v-for="(item, index) of titleList" :key="index">
          <div class="img-col" @click="handleSelectStyle(item)">
            <div class="select-mark" v-show="item.id == currentValue">
              <i class="el-icon-upload-success el-icon-check"></i>
            </div>
            <el-image :src="item.url"
              style="background-color: #f7f8fa;width: 100%;height: 220px;border-radius: 6px;"></el-image>
            <span class="content_des">{{ `风格${index + 1}` }}</span>
          </div>
        </div>
        <!-- <div class="aa" style="white-space: pre-wrap;">11</div> -->
      </div>
    </div>
    <div slot="footer" class="dialog-footer ">
      <el-button round style="width: 140px" @click="show = false">取消</el-button>
      <el-button type="primary" round style="width: 140px" @click="submit">确定</el-button>
    </div>

  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TitleStyle",
  // inject: ["value"],
  data() {
    return {
      show: false,
      currentValue: undefined,
      titleList: [
        {
          url: require('@/assets/image/style_select/title_title1.png'),
          id: 1,
        },
        {
          url: require('@/assets/image/style_select/title_title2.png'),
          id: 2,
        },
        {
          url: require('@/assets/image/style_select/title_title3.png'),
          id: 3,
        },
        {
          url: require('@/assets/image/style_select/title_title4.png'),
          id: 4,
        },
        {
          url: require('@/assets/image/style_select/title_title5.png'),
          id: 5,
        },
        {
          url: require('@/assets/image/style_select/title_title6.png'),
          id: 6,
        },
        {
          url: require('@/assets/image/style_select/title_title12.png'),
          id: 7,
        },
        {
          url: require('@/assets/image/style_select/title_title13.png'),
          id: 8,
        },
        {
          url: require('@/assets/image/style_select/title2_title1.png'),
          id: 9,
        },
        {
          url: require('@/assets/image/style_select/title2_title2.png'),
          id: 10,
        },
        {
          url: require('@/assets/image/style_select/title2_title3.png'),
          id: 11,
        },
        {
          url: require('@/assets/image/style_select/title2_title4.png'),
          id: 12,
        },
        {
          url: require('@/assets/image/style_select/title2_title5.png'),
          id: 13,
        },
        {
          url: require('@/assets/image/style_select/title3_title3.png'),
          id: 14,
        }
      ],
    };
  },
  mounted() {

  },
  computed: {
    ...mapGetters(["project", "fixedPages"]),

  },
  methods: {
    submit() {
      this.$emit('change', this.currentValue)
      this.show = false
    },
    handleSelectStyle(item) {
      this.currentValue = item.id
      // this.titleList = this.titleList.map(m => {
      //   m.isSelect = false
      //   if (m.id == item.id) {
      //     m.isSelect = true
      //   }
      //   return { ...m }
      // })
    },
    open(val) {
      this.show = true;
      this.$nextTick(() => {
        const scrollContainer = document.querySelector('.el-dialog__body')
        scrollContainer.scrollTop = 0
        this.currentValue = val
      })
      // this.$nextTick(() => {
      //   const aa = document.querySelector('.aa')

      //   let str = '_'
      //   var endTime = new Date().getTime() + 500000; // 结束时间为当前时间加5秒
      //   console.log(aa.scrollTop)
      //   function timerCallback() {
      //     // 定时器回调函数中添加判断条件
      //     const scrollContainer = document.querySelector('.el-dialog__body')
      //     if (new Date().getTime() < endTime) {
      //       // 如果当前时间还未到结束时间，则继续执行定时器
      //       console.log("定时器继续执行");
      //       str += `增加1<span style="color: brown;">122</span>\r\n`
      //       // aa.textContent = str
      //       aa.innerHTML = str
      //       scrollContainer.scrollTop = scrollContainer.scrollHeight
      //       setTimeout(timerCallback, 100); // 继续执行定时器
      //     } else {
      //       // 如果当前时间已经到达结束时间，则停止定时器
      //       console.log("定时器已停止");
      //     }
      //   }

      //   setTimeout(timerCallback, 100);

      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  overflow: auto;
}

.preview-content {
  min-height: 400px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  .content_des {
    display: flex;
    justify-content: center;
    padding: 6px 0;
  }

  .content_item {
    // width: 30%;
    width: 240px;
    margin-right: 20px;
    margin-bottom: 10px;

    .img-col {
      position: relative;
      overflow: hidden;
      border-radius: 6px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    .select-mark {
      position: absolute;
      right: -17px;
      top: -7px;
      width: 46px;
      height: 26px;
      background: #13ce66;
      text-align: center;
      transform: rotate(45deg);
      box-shadow: 0 1px 1px #ccc;
      z-index: 10;

      >i {
        font-size: 12px;
        margin-top: 12px;
        transform: rotate(-45deg);
        color: #fff;
      }

    }
  }
}

:deep(.el-dialog__body) {
  max-height: 620px;
  overflow: auto;
}

.jump-form {

  .el-form-item {
    margin-bottom: 10px;
  }
}

.tabs {
  display: flex;

  el-form-item .tab {
    width: 100px;
    height: 30px;
    color: #979797;
    border: solid 1px #ebedf0;
    cursor: pointer;
  }

  .tab-active {
    color: $color-theme;
    border: solid 1px $color-theme;
    background: #e0edff;
  }
}
</style>
<!--标题组件style_1-->
<template>
    <div class="title_com" :style="[mcTitle.wrapStyle()]">
        <div :class="['title-left-model']" :style="[mcTitle.comStyle()]">
            <img class="ti_img" src="~@/assets/image/style_select/bubble.png" alt="">
            <div class="title" :style="[mcTitle.titleStyle()]">
                {{ mcTitle.value.title || '' }}
            </div>
            <img class="ti_img" src="~@/assets/image/style_select/bubble.png" alt="" style="transform: rotateY(180deg);">
            <!-- <div class="info" :style="[mcTitle.subTitleStyle()]">{{ mcTitle.value.info || '' }}</div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleStyle_12',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title_com {
    box-sizing: content-box;

    .title-left-model {
        display: flex;
        // align-items: flex-end;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        .title {
            padding: 0 10px;
        }

        .ti_img {
            width: 35px;
        }
    }

    .title-mid-model {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>

<!--标题组件style_8-->
<template>
    <div :style="[mcTitle.wrapStyle()]">
        <div :style="[mcTitle.comStyle()]">
            <div class="title-com title1">
                <div class="title-outer" :style="{ borderColor: mcTitle.color.titleColor }">
                    <span class="inner-line" :style="{ borderColor: mcTitle.color.titleColor }"></span>
                    <span class="title" :style="[mcTitle.titleStyle(), { background: mcTitle.color.titleColor }]">{{
                        mcTitle.value.title }}</span>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    name: 'TitleStyle_8',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title-com {
    margin: 5px;
    font-size: 16px;
    text-align: center;

    .title-outer {
        position: relative;
        display: inline-block;

        .inner-line {
            position: absolute;
            top: 5px;
            right: -5px;
            bottom: -5px;
            left: 5px;
            border: 1px solid #000;
            z-index: 0;
        }

        .title {
            position: relative;
            height: 28px;
            line-height: 28px;
            padding: 0 25px;
            // border: 1px solid #000;
            z-index: 3;
            display: inline-block;
            font-size: 16px !important;
            color: #fff !important;
            background: #333;
            // background-color: #fff !important;
        }

    }

}
</style>

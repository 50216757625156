
<template>
  <div class="notice" :style="[getMarqueeStyle()]">
    <div class="img-wrap">
      <el-image :src="noticeContent.icon || noticeImg"></el-image>
    </div>
    <div class="marquee">
      <span>{{ noticeContent.text }}</span>
    </div>
  </div>
</template>

<script>
import noticeImg from '@/assets/image/notice.png'
export default {
  name: "McNotice",


  props: {
    space: {
      type: Object,
      default: () => { },
    },
    color: {
      type: Object,
      default: () => { },
    },
    noticeContent: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    getMarqueeStyle() {
      let _result = {
        color: this.color.fontColor,
        background: this.color.cmpBackground,
        padding: `${this.space.pagePadding}px 10px`,
        margin: `${this.space.MarginTop}px ${this.space.pageMargin}px 0`
        // marginTop: `${this.space.MarginTop}px`
      }
      return _result
    }
  },
  data() {
    return {
      noticeImg
    };
  },
};
</script>

<style lang="scss" scoped>
.notice {
  display: flex;
  align-items: center;

  .img-wrap {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    border-radius: 50%;
    overflow: hidden;

    .el-image {
      width: 100%;
      height: 100%;
    }

    // width: 12px;
    // height: 12px;
  }

  .marquee {
    flex: 1;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .marquee span {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 3s linear infinite;
    align-items: center;
    font-size: 16px;
  }

  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}
</style>
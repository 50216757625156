<!--模板选择容器组件-->
<template>
  <div>
    <div class="select" :class="[direction == 'column' ? 'flex-column' : '']">
      <ul class="select-label" :class="[direction == 'column' ? 'mb15' : '']">
        <li class="select-label-text" :style="[getLabelStyle()]">{{ label }}</li>
        <li class="f14">
          {{ getLabel(mValue) }}
        </li>
      </ul>

      <div class="select-body" :class="[mOptions.value ? 'linkage' : '']">
        <select-item v-for="(item, index) in data" :key="item.id || index" v-bind="item"></select-item>
      </div>
    </div>

    <div v-if="mValue.id == 2 && mOptions.value == 'promotionIDs'" class="linkage">
      <el-checkbox-group v-model="mValue.promotionIDs">
        <el-checkbox v-for="(img, index) in imgList" :label="img.PromotionID" :key="index">{{ img.Name }}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import SelectItem from "./SelectItem";
import { getPromotions } from '@/api/goods'
import { mapGetters } from "vuex";
export default {
  name: "SchemaSelect",

  components: {
    SelectItem,
  },

  mixins: [schemaMixin],

  provide() {
    return {
      mode: this,
    };
  },
  data() {
    return {
      imgList: [],
    }
  },
  mounted() {
    if (this.mOptions.value == 'promotionIDs') {
      this.getImgList()
    }
  },

  props: {
    // value: {
    //   default: {},
    // },
    data: {
      type: Array,
    },
  },

  computed: {
    ...mapGetters(["project"]),
    direction() {
      return this.data.length > 3 ? "column" : "row";
    },
  },

  methods: {
    getLabelStyle() {
      return {
        width: this.mOptions.width ? `${this.mOptions.width}px` : ''
      }
    },
    getImgList() {
      const _data = {
        shopID: this.project.MartID
      }
      getPromotions(_data).then(res => {
        if (res) {
          this.imgList = res
        }
      })
    },
    getLabel(value) {
      const valueTag = this.mOptions.value == 'promotionIDs' ? value.id : value
      return this.data.find((v) => v.value == valueTag)?.label || "暂无匹配";
    },
  },
};
</script>

<style lang="scss" scoped>
.linkage {
  padding-left: 80px;
}

.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 70px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;

  }
}

.mode-select {
  display: flex;
  justify-content: space-between;

  .mode-select-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }

  .mode-select-body {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
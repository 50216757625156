<!--页面跳转选择组件-->

<template>
  <config-item :label='label'>
    <div style="display: flex;flex-direction: column;" class="pt10">
      <el-radio-group v-model="mValue.type">
        <el-radio :label="1">上传</el-radio>
        <el-radio :label="2">外链</el-radio>
      </el-radio-group>
      <div v-if="mValue.type == 1" style="padding-top: 10px;">
        <div style="display: flex;">
          <div class="view-wrap" v-if="mValue.url">
            <!-- <pic-viewer :value="mValue" ref="picViewerRef"></pic-viewer> -->
            <!-- <div class="el-upload-list__item-actions">
              <i @click="handlePreview" class="el-icon-zoom-in"></i>
              <i @click="handleDelete" class="el-icon-delete"></i>
            </div> -->
            <video :src="mValue.url" style="width: 100%;height: 100%;"></video>
          </div>
          <div class="upload-wrap" @click="handleSelectFile">
            <i class="el-icon-picture-outline"></i>
            <span class="upload-desc">请选择视频</span>
          </div>
        </div>
        <!-- <SchemaUpload style="padding-bottom: 0 !important;padding-left: 0 !important;" /> -->
      </div>
      <div v-else-if="mValue.type == 2" style="padding-top: 10px;">
        <el-input type="textarea" :rows="3" placeholder="请输入链接~" v-model="mValue.outUrl">
        </el-input>
      </div>
    </div>

    <UploadDialog ref="uploadDiaRef" uploadType="video" @select="handleSelect" />

  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

import UploadDialog from '@/components/UploadDialog'
export default {
  name: "SchemaVideoSource",
  mixins: [schemaMixin],
  components: {
    UploadDialog
  },
  props: {
    value: {
      default: () => ({}),
    },
  },

  computed: {
    pageName() {
      // console.log('1', this.mValue)
      let page = null;
      page = {
        name: `${this.value?.id}_${this.value.type}`
        // name: `${this.value.name}`
      }
      return page?.name;
    },
  },


  methods: {
    handleSelect(list) {
      this.mValue.url = list[0]
    },
    /**打开文件选择弹框 */
    handleSelectFile() {
      this.$nextTick(() => {
        this.$refs["uploadDiaRef"].open()
      })
    },

    handleOk(val) {
      this.mValue = { ...val }
    },
    open() {
      this.$refs.jump.open(this.mValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.view-wrap {
  width: 72px;
  height: 72px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 10px;

  // overflow: hidden;
  // border-radius: 6px;
  background-color: #fff;
  border: 1px solid #c0ccda;
  // border-radius: 6px;

  .el-upload-list__item-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .2s;
    display: flex;
    justify-content: space-around;
    align-items: center;


    >i {
      cursor: pointer;
      color: #fff;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.upload-wrap {
  width: 72px;
  height: 72px;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  .upload-desc {
    padding-top: 6px;
    font-size: 10px;
  }
}
</style>

<!--标题组件style_1-->
<template>
    <div :style="[mcTitle.wrapStyle()]">
        <div :style="[mcTitle.comStyle()]">
            <div class="title-com title1">
                <div class="title-outer" style="border-color: rgb(51, 51, 51);">
                    <span class="line" :style="{ background: mcTitle.color.titleColor }"></span>
                    <span class="title bold" :style="[mcTitle.titleStyle()]">{{ mcTitle.value.title }}</span>
                    <span class="line" :style="{ background: mcTitle.color.titleColor }"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleStyle_1',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title-com {
    margin: 5px;
    font-size: 16px;
    text-align: center;

    .title-outer {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        .title {
            padding: 0 10px;
            font-size: 16px;
        }

        .line {
            display: block;

            width: 30px;
            height: 1px;
            background: #000;
            // margin-left: -30px;
        }
    }

}
</style>

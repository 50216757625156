<!--页面跳转选择组件-->

<template>
  <config-item :label='label'>
    <div class="flex col-center h32">
      <div v-if="!mValue.type" class="f12 f-theme pointer" @click="open">
        选择跳转到的页面
      </div>

      <template v-else>
        <el-tag size='small' style="max-width: 230px;" class="ellipsis-1">{{ mValue.name }}</el-tag>
        <span class="ml5 f12 f-theme pointer" @click="open">修改</span>
      </template>
    </div>

    <JumpDialog ref='jump' @ok="handleOk"></JumpDialog>

  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
// import { mapGetters } from "vuex";

export default {
  name: "SchemaJump",

  mixins: [schemaMixin],

  // provide() {
  //   return {
  //     value: this.value,
  //   };
  // },

  props: {
    value: {
      default: () => ({}),
    },
  },

  computed: {
    // ...mapGetters(["project", "fixedPages"]),
    pageName() {
      console.log('1', this.mValue)
      let page = null;
      page = {
        name: `${this.value?.id}_${this.value.type}`
        // name: `${this.value.name}`
      }
      return page?.name;
    },
  },


  methods: {
    handleOk(val) {
      this.mValue = { ...val }
    },
    open() {
      this.$refs.jump.open(this.mValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>

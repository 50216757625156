<!--商品类型选择-->
<template>
  <div>
    <div class="flex p10 bg-white">
      <span class="w70 f13 f-grey pt10">图片信息</span>
      <div class=" flex-1">
        <div class="upload-wrap">
          <el-image class="img" :src="storeInfo.Logo" alt="" />
        </div>
      </div>
    </div>
    <div class="flex p10 bg-white items-center">
      <span class="w70 f13 f-grey pr-10">商家名称</span>
      <el-input disabled v-model="storeInfo.Name"></el-input>
    </div>
    <div class="flex p10 bg-white items-center">
      <span class="w70 f13 f-grey pr-10">描述</span>
      <el-input disabled v-model="storeInfo.Remarks"></el-input>
    </div>

  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { getShopInfo } from '@/api/project'
import { mapGetters } from "vuex";
export default {
  name: "SchemaStoreInfo",

  mixins: [schemaMixin],
  created() {
    this.getShopDetail()
  },
  provide() {
    return {
      mode: this,
    };
  },
  data() {
    return {
      featureList: [],
      recommendList: [],
      storeInfo: {}
    };
  },

  props: {
    value: {
      default: () => { },
    },
    data: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters(["project"]),
  },
  methods: {
    getShopDetail() {
      const _data = {
        shopID: this.project.MartID
      }
      getShopInfo(_data).then(res => {
        this.storeInfo = { ...res }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.config-item-wrap {
  display: flex;
  align-items: center;
}

.upload-wrap {
  width: 72px;
  height: 72px;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  .img {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div class="wrap">

    <div class="wrap-label">
      <span>{{ label }}</span>
    </div>

    <div class="wrap-body">
      <slot></slot>

      <div v-show="line" class="wrap-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchemaObject",
  props: {
    label: {
      type: String,
      default: "",
    },
    line: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background: #fff;

  .wrap-label {
    padding: 10px 12px;
    background: #e8f0fb40;
    font-size: 14px;
    color: #323233;
    font-weight: 550;

    span {
      &::before {
        content: ".";
        width: 3px;
        height: 10px;
        margin-right: 8px;
        background: $color-theme;
      }
    }
  }

  .wrap-body {
    padding: 10px 20px 10px 20px;

    .wrap-line {
      margin-top: 30px;
      height: 1px;
      background: #ebedf0;
    }
  }
}
</style>
<!--标题组件-->
<template>
	<div class="store-bar" :style="[$wrapStyle(styles)]">
		<div class="store-bar-model_1" :style="[$cmpStyle(styles)]" v-if="selectionType == 1">
			<el-image class="img" mode="widthFix" :style="[getImgStyle()]" :src="storeInfo.Logo || defaultImage" />
			<div class="info">
				<div class="info-name" :style="[titleStyle()]">{{ storeInfo.Name }}</div>
				<div class="info-sub" :style="[subTitleStyle()]">{{ storeInfo.Remarks }}</div>
			</div>
		</div>
		<div class="store-bar-model" :style="[$cmpStyle(styles)]" v-if="selectionType == 2">
			<div class="model-top">
				<el-avatar :size="45" :src="storeInfo.Logo || defaultImage"></el-avatar>
				<div class="text">
					<p class="ellipsis-1">{{ storeInfo.Name }}</p>
					<span>营业时间 09:00-24:00</span>
				</div>
				<div class="end-icon">
					<!-- <img sr="../../assets/image/store_style/call.png" style="width: 23px;height: 23px;" /> -->
					<span class="call"></span>
					<span class="share"></span>
				</div>
			</div>
			<div class="model-bottom">
				<el-avatar :size="25" :src="style_1 || defaultImage"></el-avatar>
				<div class="text">
					<span>立即下单</span>
					<span class="text-red-700">冲！冲！冲！</span>
				</div>
				<div class="btn">立即签约</div>
			</div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getShopInfo } from '@/api/project'
import style_1 from '@/assets/image/store_style/add.png'
export default {
	name: 'McStoreBar',

	props: {
		imageValue: {
			type: Object,
			default: () => { },
		},
		space: {
			type: Object,
			default: () => { }
		},
		radius: {
			type: Object,
			default: () => { }
		},
		color: {
			type: Object,
			default: () => { }
		},
		size: {
			type: Object,
			default: () => { }
		},
		modes: {
			type: Object,
			default: () => { }
		},
		// attrs: {
		// 	type: Object,
		// 	default: () => { }
		// },
		value: {
			type: Object,
			default: () => { }
		}
	},
	data() {
		return {
			style_1,
			storeInfo: {},
			defaultImage: "https://img01.yzcdn.cn/public_files/2019/03/05/2b60ed750a93a1bd6e17fc354c86fa78.png!large.webp",
		};
	},
	mounted() {
		this.getShopDetail()
	},
	computed: {
		selectionType() {
			return this.modes.selection || 1
		},
		...mapGetters(["project"]),
		styles() {
			return { ...this.space, ...this.radius, ...this.color }
		}
	},
	methods: {

		getShopDetail() {
			const _data = {
				shopID: this.project.MartID
			}
			getShopInfo(_data).then(res => {
				this.storeInfo = { ...res }
			})
		},
		getImgStyle() { },
		// baseStyle() {
		// 	return this.$baseStyle(this.styles)
		// },
		// 主标题样式
		titleStyle() {
			return {
				color: this.color.titleColor,
				fontSize: this.$unit(this.size.titleSize),
				fontWeight: this.size.titleWeight
			}
		},
		// 副标题样式
		subTitleStyle() {
			return {
				color: this.color.infoColor,
				fontSize: this.$unit(this.size.infoSize),
				fontWeight: this.size.infoWeight
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.store-bar {
	.img {
		width: 120px;
		height: 120px;
	}

	.store-bar-model_1 {
		display: flex;

		.info {
			flex: 1;
			padding: 5px 0 10px 20px;
			display: inline-flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	.store-bar-model {
		// display: flex;
		font-size: 14px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 500;
		color: #333333;

		.model-bottom {
			height: 37px;
			background: #FFEFEF;
			border-radius: 6px;
			margin-top: 6px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;

			.text {
				flex: 1;
				padding-left: 12px;
				font-size: 12px;
				font-family: Inter, Inter;
				font-weight: 400;
				color: #333333;
			}

			.btn {
				padding: 6px 16px;
				background: #FF3B46;
				border-radius: 26px;
				font-size: 12px;
				color: #fff;
			}
		}

		.model-top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding-right: 14px;

			.text {
				flex: 1;
				padding-left: 10px;
				display: flex;
				flex-direction: column;

				>p {
					font-size: 14px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					color: #333333;
					display: inline-flex;
				}

				>span {
					font-size: 12px;
					font-family: Inter, Inter;
					font-weight: 500;
					color: #999999;
					display: inline-flex;
					margin-top: 8px;
				}
			}

			.end-icon {
				display: inline-flex;

				>span {
					width: 25px;
					height: 25px;
					border-radius: 100%;
					cursor: pointer;

					&+span {
						margin-left: 12px;
					}

					&.call {
						background-image: url(../../assets/image/store_style/call.png);
						background-size: contain;
					}

					&.share {

						background-image: url(../../assets/image/store_style/share.png);
						background-size: contain;
					}
				}
			}

			:deep(.el-avatar) {
				>img {
					width: 100%;
					height: 100%;
				}
			}
		}


	}

	.img-left-model {
		flex-direction: row;
		// justify-content: flex-end;
	}

	.img-right-model {
		flex-direction: row-reverse;
		// justify-content: flex-end;
	}

	// .title-mid-model {
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	// }
}
</style>

<!--标题组件style_5-->
<template>
    <div :style="[mcTitle.wrapStyle()]">
        <div :style="[mcTitle.comStyle()]">
            <div class="title-com title1">
                <div class="title-outer" style="border-color: rgb(51, 51, 51);">
                    <span class="line left" :style="{ background: mcTitle.color.titleColor }"></span>
                    <span class="title bold" :style="[mcTitle.titleStyle()]">{{ mcTitle.value.title }}</span>
                    <span class="line right" :style="{ background: mcTitle.color.titleColor }"></span>
                    <span class="center-line" :style="{ borderColor: mcTitle.color.titleColor }"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleStyle_5',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title-com {
    margin: 5px;
    font-size: 16px;
    text-align: center;

    .title-outer {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        .title {
            // padding: 0 10px;
            font-size: 16px !important;
            margin-bottom: 12px;
        }

        .center-line {
            width: 9px;
            height: 9px;
            border: 1px solid #000;
            position: absolute;
            display: inline-block;
            bottom: -1px;
            left: 50%;
            margin-left: -5px;
            z-index: 2;
            transform: rotate(45deg);
        }

        .line {
            width: 160px;
            height: 1px;
            background: #000;
            position: absolute;
            bottom: 0;

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }
    }

}
</style>


<template>
  <config-item :label='label'>
    <div class="flex items-center flex-1">
      <el-slider v-model="mValue" v-bind="mOptions" show-input>
      </el-slider>
      <span class="ml10 f12">px</span>
    </div>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaNumber",

  mixins: [schemaMixin],

  data() {
    return {
      defaultOptions: {
        max: 600,
      },
    };
  },
};
</script>
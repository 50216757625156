<!--商品组件-->
<template>
	<div :style="[$wrapStyle({ ...space, ...radius, ...color })]">
		<goods-tabs :config="config">
			<template v-slot="{ list }">
				<goods-list :list="list"></goods-list>
			</template>
		</goods-tabs>
	</div>
</template>
  
<script>
import GoodsTabs from "./GoodsTabs.vue";
import GoodsList from "./GoodsList.vue";
export default {
	name: "McRecommendedGoods",

	components: { GoodsTabs, GoodsList },

	props: {
		space: {
			type: Object,
			default: () => { },
		},
		radius: {
			type: Object,
			default: () => { },
		},
		color: {
			type: Object,
			default: () => { },
		},
		attrs: {
			type: Object,
			default: () => { },
		},
		goodsData: {
			type: Array,
			default: () => [],
		},
		type: {
			type: Object,
			default: () => { }
		}
	},

	computed: {
		config() {
			return {
				styles: { ...this.space, ...this.radius, ...this.color },
				attrs: this.attrs,
				goodsData: this.goodsData,
				type: this.type
			};
		},
	},
};
</script>
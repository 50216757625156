
<template>
  <div class="wrap" :style="[getWrapStyle()]">
    <div class="list" :style="[getStyle()]">
      <!-- 商品列表 -->
      <template v-if="mList.length">
        <goods-item v-for="(item, index) in mList" :key="index" :item="item"></goods-item>
      </template>

      <div v-else class="flex-column row-center w-100 pt20 pb20 bg-white f14 f-grey" style="height: 320px">
        <!-- 加载中 -->
        <template v-if="loading"></template>

        <!-- 空列表 -->
        <template v-else>
          <el-image width="180" :src="emptyImg" />
          <span class="mt20">暂无商品</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsItem from "./GoodsItem.vue";
import { getGoodsListByIds } from "@/api/goods.js";
import { mapGetters } from "vuex";
import emptyImg from '@/assets/image/empty.png'
export default {
  name: "GoodsList",

  components: { GoodsItem },

  inject: ["tabs"],

  props: {
    list: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      emptyImg,
      loading: false,
      mList: [],
    };
  },

  computed: {
    ...mapGetters(["project"]),

    attr() {
      return this.tabs.config.attrs;
    },
  },

  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.getList();
      },
    },
  },

  methods: {
    async getList() {
      this.loading = true;
      const { list } = await getGoodsListByIds({
        productType: 8,
        // projectId: this.project.id,
        productMainIDs: this.list,
        shopID:this.project.MartID, 
      });
      // let list = Detail?.list || []
      this.mList = list;
      this.loading = false;
    },

    getWrapStyle() {
      this.attr.listModel == "roll" ? { overflowX: "auto" } : {};
    },

    getStyle() {
      if (this.attr.listModel == "roll") {
        return {
          display: "flex",
          width: this.list.length ? `${this.$unit((375 / 2.5) * this.list.length)}` : 'auto',
        };
      } else {
        const _result = {
          display: "flex",
          flexWrap: "wrap",
          padding: `${this.$unit(5)} 0`,
        }
        return _result;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  height: 200px;
  margin: auto;
}

.wrap {

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }
}

:deep(.slot-body) {
  display: flex;
  justify-content: center;
  background: #fff;
}
</style>
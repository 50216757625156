<!--选择或上传图片组件-->
<template>
  <el-dialog title="选择文件" :visible.sync="show" width="55%">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="直接上传" name="1">
        <Imgpond v-model="uploadValue" :type="uploadType" :accept="acceptString" :editable="false"
          @success="handleSuccess" />
      </el-tab-pane>
      <el-tab-pane label="从云盘选择" name="2">
        <el-scrollbar style="flex: 1; height: 45vh">
          <el-row :gutter="10" style="width: 100%">
            <el-col :span="6" v-for="item of fileList" :key="item.ID" style="margin-bottom: 10px">
              <div class="img-col" @click="handleSelectFile(item.ID)">
                <div class="select-mark" v-show="item.isSelect">
                  <i class="el-icon-upload-success el-icon-check"></i>
                </div>
                <video :src="item.FileUrl" v-if="uploadType == 'video'"
                  style="height: 180px; width: 100%;object-fit: contain"></video>
                <img v-else :src="getImgUrl(item)" style="height: 180px; width: 100%;object-fit: contain" />
              </div>
            </el-col>
          </el-row>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer upload-footer">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
        :page-sizes="[30, 50, 100, 200]" :page-size="30" layout="total, sizes, prev, pager, next" :total="total">
      </el-pagination>
      <el-button type="primary" size="small" @click="submit">确认选择</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getFilePage, getFileVideoPage } from '@/api/goods'
import videoDefault from '@/assets/image/video_default.jpg'
export default {
  name: "UploadDialog",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    uploadType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      uploadValue: '',
      activeName: '1',
      selectList: [],
      show: false,
      fileList: [],
      pageSize: 30,
      pageIndex: 1,
      total: 0,
    };
  },
  mounted() {
  },
  computed: {
    acceptString() {
      return this.uploadType == 'video' ? '.mp4' : '.jpg,.jpeg,.png'
    }
  },
  methods: {
    getImgUrl(item) {
      const _type = item.FileUrl.split('.').pop().toLowerCase()
      const _video = ['mp4', 'avi', 'mov', 'flv', 'mkv', 'm4v', 'webm', 'mpeg', '3gp']
      if (_video.includes(_type)) {
        // const video = document.createElement('video');
        // video.src = item.FileUrl;
        // video.addEventListener('loadeddata', () => {
        //   video.currentTime = 10
        //   const canvas = document.createElement('canvas');
        //   canvas.width = video.videoWidth;
        //   canvas.height = video.videoHeight;

        //   const ctx = canvas.getContext('2d');
        //   ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        //   const coverImageUrl = canvas.toDataURL('image/jpeg');
        //   console.log(coverImageUrl);
        //   // 使用 coverImageUrl 进行后续操作，如显示、保存等
        // });
        return videoDefault
      } else {
        return `${item.FileUrl}@!small`
      }
    },
    handleSuccess() {
      this.pageIndex = 1
      this.getFileList()
    },
    handleSelectFile(id) {
      this.fileList = this.fileList?.map(m => {
        if (!this.multiple) {
          m.isSelect = false
        }
        if (m.ID == id) {
          m.isSelect = !m.isSelect
        }
        return { ...m }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getFileList()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getFileList()
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    open() {
      this.show = true;
      this.uploadValue = ''
      this.getFileList()
    },

    submit() {
      // console.log(this.uploadValue)
      const _list = this.fileList.filter(f => f.isSelect)?.map(m => m.FileUrl)
      if (_list && _list.length > 0) {
        this.show = false
        this.$emit('select', _list)
      } else {
        this.$message({
          message: '请选择图片',
          type: 'warning'
        });
      }
    },

    getFileList() {
      const _data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex
      }
      if (this.uploadType == 'video') {
        getFileVideoPage(_data).then(res => {
          if (res) {
            this.fileList = res.List
            this.total = res.TotalCount
          }
        }).finally(() => { })
      } else {
        getFilePage(_data).then(res => {
          if (res) {
            this.fileList = res.List
            this.total = res.TotalCount
          }
        }).finally(() => { })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-scrollbar__wrap) {
  overflow-x: auto;
}

.upload-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-mark {
  position: absolute;
  right: -17px;
  top: -7px;
  width: 46px;
  height: 26px;
  background: #13ce66;
  text-align: center;
  transform: rotate(45deg);
  box-shadow: 0 1px 1px #ccc;
  z-index: 10;

  >i {
    font-size: 12px;
    margin-top: 12px;
    transform: rotate(-45deg);
    color: #fff;
  }

}

.img-col {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #c0ccda;
  box-sizing: border-box;
}


:deep(.el-button--primary) {
  background: $color-theme !important;
}


.form-item-none {
  border-color: transparent;
}
</style>
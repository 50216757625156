<!--标题组件style_9-->
<template>
    <div class="title_com" :style="[mcTitle.wrapStyle()]">
        <div :style="[mcTitle.comStyle()]">
            <div class="top">
                <img class="ti_img" src="~@/assets/image/style_select/t1_bg.png" alt="">
                <div class="title" :style="[mcTitle.titleStyle()]">
                    {{ mcTitle.value.title || '' }}
                </div>
                <img class="ti_img" src="~@/assets/image/style_select/t1_bg.png" alt="" style="transform: rotateY(180deg);">
            </div>
            <div class="bottom">
                <div class="info" :style="[mcTitle.subTitleStyle()]">{{ mcTitle.value.info || '' }}</div>
            </div>
            <!--  -->
        </div>
    </div>
</template>


<script>
export default {
    name: 'TitleStyle_9',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title_com {
    box-sizing: content-box;

    .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
    }

    .top {
        display: flex;
        // align-items: flex-end;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        .title {
            padding: 0 10px;
        }

        .ti_img {
            width: 20px;
        }
    }

    .title-mid-model {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>

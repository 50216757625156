<!--标题组件-->
<template>
	<component :is="curComponent"></component>
</template>

<script>
export default {
	name: 'McTitle',

	provide() {
		return {
			mcTitle: this
		}
	},
	data() {
		return {
			componentList: [
				{
					id: 1,
					name: 'TitleStyle_1'
				},
				{
					id: 2,
					name: 'TitleStyle_2'
				},
				{
					id: 3,
					name: 'TitleStyle_3'
				},
				{
					id: 4,
					name: 'TitleStyle_4'
				},
				{
					id: 5,
					name: 'TitleStyle_5'
				},
				{
					id: 6,
					name: 'TitleStyle_6'
				},
				{
					id: 7,
					name: 'TitleStyle_7'
				},
				{
					id: 8,
					name: 'TitleStyle_8'
				},
				{
					id: 9,
					name: 'TitleStyle_9'
				},
				{
					id: 10,
					name: 'TitleStyle_10'
				},
				{
					id: 11,
					name: 'TitleStyle_11'
				},
				{
					id: 12,
					name: 'TitleStyle_12'
				},
				{
					id: 13,
					name: 'TitleStyle_13'
				},
				{
					id: 14,
					name: 'TitleStyle_14'
				},
			]
		}
	},
	props: {
		modes: {
			type: Object,
			default: () => { }
		},
		attrs: {
			type: Object,
			default: () => { }
		},
		value: {
			type: Object,
			default: () => { }
		},
		space: {
			type: Object,
			default: () => { }
		},
		radius: {
			type: Object,
			default: () => { }
		},
		color: {
			type: Object,
			default: () => { }
		},
	},
	computed: {

		curComponent() {
			return this.componentList.find(f => f.id == this.modes.selection)?.name || 'TitleStyle_1'
		},
		styles() {
			return { ...this.space, ...this.radius, ...this.color }
		},
	},
	methods: {
		wrapStyle() {
			return {
				overflow: 'hidden',
				background: this.color.cmpBackground,
				borderRadius: `${this.radius.cmpUpperRadius}px ${this.radius.cmpUpperRadius}px ${this.radius.cmpLowerRadius}px ${this.radius.cmpLowerRadius}px`,
				// marginTop: `${this.space.MarginTop}px`
				margin: `${this.space.MarginTop}px ${this.space.pageMargin}px 0`
			}
		},
		comStyle() {
			return {
				margin: `${this.space.cmpUpperMargin}px ${this.space.pagePadding}px ${this.space.cmpLowerMargin}px`,
			}
		},
		titleStyle() {
			return {
				color: this.color.titleColor,
				fontSize: this.$unit(20),
				// fontWeight: this.styles.infoWeight
			}
		},
		subTitleStyle() {
			return {
				color: this.color.infoColor,
				fontSize: this.$unit(12),
				// fontWeight: this.styles.infoWeight
			}
		}
	},
}
</script>

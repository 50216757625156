<template>
  <config-item :label='label'>
    <div style="display: flex;">
      <div class="view-wrap" v-if="mValue">
        <pic-viewer :value="mValue" ref="picViewerRef"></pic-viewer>
        <div class="el-upload-list__item-actions">
          <i @click="handlePreview" class="el-icon-zoom-in"></i>
          <i @click="handleDelete" class="el-icon-delete"></i>
        </div>
      </div>
      <div class="upload-wrap" @click="handleSelectFile">
        <i class="el-icon-picture-outline"></i>
        <span class="upload-desc">请选择图片</span>
      </div>
    </div>
    <!-- <Imgpond v-model="mValue" v-bind="mOptions" :count="1" /> -->
    <UploadDialog ref="uploadDiaRef" uploadType="img" @select="handleSelect" />
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import UploadDialog from '@/components/UploadDialog'
export default {
  name: "SchemaUpload",

  mixins: [schemaMixin],
  components: {
    UploadDialog
  },
  methods: {
    handleSelect(list) {
      this.mValue = list[0]
    },
    handlePreview() {
      this.$refs['picViewerRef'].viewer.view()
    },

    handleDelete() {
      this.mValue = ''
    },
    /**打开文件选择弹框 */
    handleSelectFile() {
      this.$nextTick(() => {
        this.$refs["uploadDiaRef"].open()
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.view-wrap {
  width: 72px;
  height: 72px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 10px;

  // overflow: hidden;
  // border-radius: 6px;
  background-color: #fff;
  border: 1px solid #c0ccda;
  // border-radius: 6px;

  .el-upload-list__item-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .2s;
    display: flex;
    justify-content: space-around;
    align-items: center;


    >i {
      cursor: pointer;
      color: #fff;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.upload-wrap {
  width: 72px;
  height: 72px;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  .upload-desc {
    padding-top: 6px;
    font-size: 10px;
  }
}

:deep(.pic-viewer) {
  img {
    width: 72px !important;
    height: 72px !important;
  }
}
</style>
<!--商品类型选择-->
<template>
  <div>
    <div class="flex p10 bg-white flex-center">
      <span class="w70 f13 f-grey pr-10">{{ label }}</span>
      <el-select v-model="mValue.id" placeholder="请选择" style="flex: 1;">
        <el-option v-for="(item, index) in data" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="flex p10 bg-white" style="align-items: flex-start;" v-if="mValue.id == 2">
      <span class="w70 f13 f-grey pr-10">商品特征</span>
      <div style="flex: 1;">
        <el-checkbox-group v-model="mValue.featureIDs">
          <el-checkbox v-for="feature in featureList" :label="feature.FeatureID" :key="feature.FeatureID">{{ feature.Name
          }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="flex p10 bg-white flex-center" v-if="mValue.id == 4">
      <span class="w70 f13 f-grey pr-10">推荐清单</span>
      <el-select v-model="mValue.RecommendID" placeholder="请选择" style="flex: 1;">
        <el-option v-for="item in recommendList" :key="item.RecommendID" :label="item.Title" :value="item.RecommendID">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { getProductFeature, getRecommentdList } from '@/api/goods'
export default {
  name: "SchemaDownSelect",

  mixins: [schemaMixin],
  created() {
    this.getFeature()
    this.getRecommend()
  },
  provide() {
    return {
      mode: this,
    };
  },
  data() {
    return {
      featureList: [],
      recommendList: [],
    };
  },

  props: {
    value: {
      default: () => { },
    },
    data: {
      type: Array,
    },
  },

  methods: {
    getFeature() {
      getProductFeature().then(res => {
        this.featureList = res || []
        console.log(res)
      })
    },
    getRecommend() {
      getRecommentdList().then(res => {
        this.recommendList = res || []
      })
    },
    getLabel(value) {
      return this.data.find((v) => v.value == value)?.label || "暂无匹配";
    },
  },
  // watch: {
  //   mValue: {
  //     immediate: true,
  //     handler(val) {
  //       console.log('推荐', val)
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.config-item-wrap {
  display: flex;
  align-items: center;
}

.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 70px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
  }
}

.mode-select {
  display: flex;
  justify-content: space-between;

  .mode-select-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }

  .mode-select-body {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
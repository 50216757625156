import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jump from '@/utils/jump'
import '@/scss/index.scss'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 适配
import '@/utils/adapter.js'

import globalMethods from '@/utils/globalMethods'
import {
  getWrapStyle,
  getComponentStyle,
  getMultiBackground
} from '@/utils/style'

// request
import request from '@/utils/request'

// 全局注册组件
import '@/utils/globalRegister.js'

// 全局注册过滤器
import '@/utils/filters.js'

// 注册公用渲染方法
import '@/utils/registerBaseStyle.js'

// element css
// import 'element-ui/lib/theme-chalk/index.css';
// import '@/scss/element-#82AAF1/index.css'

// vant css
// import 'vant/lib/index.css'

import draggable from 'vuedraggable'
import ElementVerify from 'element-verify'
import _ from 'lodash'
// import global from '@/config/global'

Vue.config.productionTip = false

Vue.prototype._ = _

Vue.component('draggable', draggable)

// 挂载
Vue.use(ElementUI)
Vue.use(globalMethods)
Vue.use(ElementVerify)
Vue.prototype.$ELEMENT = { size: 'small' };

/**
 * Axios 捷径
 */
import createAxiosShortcut from 'axios-shortcut'
const axiosShortcut = createAxiosShortcut(request)
for (let k in axiosShortcut) {
  if (!Vue.prototype[`$${k}`]) {
    Object.defineProperty(Vue.prototype, `$${k}`, {
      value: axiosShortcut[k]
    })
  }
}

/**
 * 图片预览
 */
import 'pic-viewer/dist/style.css'
import PicViewer from 'pic-viewer'
Vue.use(PicViewer)

/**
 * 图片上传
 */
import 'imgpond/dist/style.css'
import Imgpond from 'imgpond'
import videoDefault from '@/assets/image/video_default.jpg'
Vue.use(Imgpond, {
  upload: (file, context) => new Promise((resolve, reject) => {
    const _url = context.$attrs.type == 'video' ? `${process.env.VUE_APP_BASE_API}/upload/uploadVideo` : `${process.env.VUE_APP_BASE_API}/upload/upload`
    Vue.prototype.$POST.upload(_url, ({
      file,
      ...context.$attrs.requestParam,
    }), {
      baseURL: '',
      timeout: 20000,
    }).then(res => {
      const _type = res.data.split('.').pop().toLowerCase()
      const _video = ['mp4', 'avi', 'mov', 'flv', 'mkv', 'm4v', 'webm', 'mpeg', '3gp']
      if (_video.includes(_type)) {
        resolve(videoDefault)
      } else {
        resolve(res.data)
      }
    }).catch(e => {
      reject(e)
    })
  }),
})

/**
 * 富文本
 */
// import useMiniMCE from '@/components/MiniMCE'
// useMiniMCE()

Vue.prototype.$jump = jump
Vue.prototype.$getWrapStyle = getWrapStyle
Vue.prototype.$getComponentStyle = getComponentStyle
Vue.prototype.$getMultiBackground = getMultiBackground

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

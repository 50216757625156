
<template>
  <el-dialog title="风格选择器" class="style-select-dia" :visible.sync="show" top="10vh" width="810px">
    <div class="preview">
      <div class="preview-content">
        <div class="content_item" v-for="(item, index) of titleList" :key="index">
          <div class="img-col" @click="handleSelectStyle(item)">
            <div class="select-mark" v-show="item.id == currentValue">
              <i class="el-icon-upload-success el-icon-check"></i>
            </div>
            <el-image :src="item.url" style="background-color: #f7f8fa;width: 100%;height: 140px;border-radius: 6px;"
              fit="scale-down"></el-image>
            <span class="content_des">{{ `风格${index + 1}` }}</span>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer ">
      <el-button round style="width: 140px" @click="show = false">取消</el-button>
      <el-button type="primary" round style="width: 140px" @click="submit">确定</el-button>
    </div>

  </el-dialog>
</template>

<script>

export default {
  name: "StoreStyle",
  data() {
    return {
      show: false,
      currentValue: undefined,
      titleList: [
        {
          url: require('@/assets/image/store_style/style_1.png'),
          id: 1,
        },
        {
          url: require('@/assets/image/store_style/style_2.png'),
          id: 2,
        },
      ],
    };
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    submit() {
      this.$emit('change', this.currentValue)
      this.show = false
    },
    handleSelectStyle(item) {
      this.currentValue = item.id

    },
    open(val) {
      this.show = true;
      this.$nextTick(() => {
        const scrollContainer = document.querySelector('.el-dialog__body')
        scrollContainer.scrollTop = 0
        this.currentValue = val
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  overflow: auto;
}

.preview-content {
  min-height: 400px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  .content_des {
    display: flex;
    justify-content: center;
    padding: 6px 0;
  }

  .content_item {
    width: 46%;
    // width: 240px;
    margin-right: 20px;
    margin-bottom: 10px;

    .img-col {
      position: relative;
      overflow: hidden;
      border-radius: 6px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    .select-mark {
      position: absolute;
      right: -17px;
      top: -7px;
      width: 46px;
      height: 26px;
      background: #13ce66;
      text-align: center;
      transform: rotate(45deg);
      box-shadow: 0 1px 1px #ccc;
      z-index: 10;

      >i {
        font-size: 12px;
        margin-top: 12px;
        transform: rotate(-45deg);
        color: #fff;
      }

    }
  }
}

:deep(.el-dialog__body) {
  max-height: 620px;
  overflow: auto;
}
</style>
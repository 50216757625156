import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/login'),
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import('@/pages/manage'),
  },
  {
    path: '/mall',
    name: 'mall',
    component: () => import('@/pages/mall'),
    redirect: '/mall/pages-manage',
    children: [
      {
        path: 'pages-manage',
        name: 'pages-manage',
        component: () => import('@/pages/mall/pages-manage'),
      },
      {
        path: 'page-build',
        name: 'page-build',
        component: () => import('@/pages/mall/page-build'),
      },
      {
        path: 'store',
        name: 'store',
        component: () => import('@/pages/mall/store'),
        redirect: '/mall/store/navigation-tpl',
        children: [
          {
            path: 'navigation-tpl',
            name: 'navigation-tpl',
            component: () => import('@/pages/mall/store/navigation-tpl'),
          },
          {
            path: 'search-tpl',
            name: 'search-tpl',
            component: () => import('@/pages/mall/store/search-tpl'),
          },
          {
            path: 'category-tpl',
            name: 'category-tpl',
            component: () => import('@/pages/mall/store/category-tpl'),
          },
          {
            path: 'list-tpl',
            name: 'list-tpl',
            component: () => import('@/pages/mall/store/list-tpl'),
          },
        ]
      },
      {
        path: 'model-manage',
        name: 'model-manage',
        component: () => import('@/pages/mall/model-manage'),
      },
      {
        path: "/404",
        component: () => import("@/pages/error-page/404.vue"),
        meta: {
          hidden: true
        },
        alias: "/:pathMatch(.*)*"
      },
    ]
  }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})

// 全局路由守卫鉴权
router.beforeEach((to, from, next) => {
  if (to.name == 'login') {
    next()
  } else {
    if (!store.getters.token) {
      next('/')
    } else {
      next()
    }
  }
})

export default router

<!--图片魔方配置-->
<template>
  <div class="wrap">
    <div class="wrap-label">
      <span>魔方数据</span>
    </div>
    <div class="wrap-body">

      <!-- 布局风格选择 -->
      <config-item label="魔方风格">
        <div class="flex col-center h32">
          <div v-if="!mValue.model" class="f12 f-theme pointer" @click="open">选择风格</div>

          <template v-else>
            <el-tag size='small'>{{ pageName }}</el-tag>
            <span class="ml5 f12 f-theme pointer" @click="open">修改</span>
          </template>
        </div>
      </config-item>

      <div>
        <div v-for="(item, index) of mValue.list" :key="index">
          <SchemaUpload label="选择图片" v-model="item.imagePath" />
          <SchemaJump label="跳转页面" v-model="item.jump"></SchemaJump>
        </div>
      </div>

    </div>
    <MagicStyle ref="jump" @change="handleChange" />
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaMagic",

  mixins: [schemaMixin],

  data() {
    return {
      activeItem: null,
    };
  },
  computed: {
    imgList() {
      return this.mValue.list
    },
    pageName() {
      let page = null;
      page = {
        name: `风格${this.value.model}`
      }
      return page?.name;
    },
  },

  methods: {
    handleChange(item) {
      const { val, len } = item
      this.mValue.model = val
      // this.mValue.list = Array.from({ len }, () => {
      //   return {
      //     imagePath: ''
      //   }
      // })
      // this.mValue.list = Array(len).fill({
      //   imagePath: ''
      // })
      this.mValue.list = Array.from({ length: len }, () => ({
        imagePath: '',
        jump: {
          "type": "",
          "id": ""
        }
      }))
      console.log(this.mValue.list, item)
    },
    open() {
      // console.log(this.value)
      this.$refs.jump.open(this.value.model);
    },

    // 切换行数
    changeRow() {
      this.$refs.layout.reset();
    },

    // 切换单元块设置 
    onCurIndex(item) {
      this.activeItem = null;
      setTimeout(() => {
        this.activeItem = item;
        console.log(item)
      }, 10);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  background: #fff;

  .wrap-label {
    padding: 10px 12px;
    background: #e8f0fb40;
    font-size: 14px;
    color: #323233;
    font-weight: 550;

    span {
      &::before {
        content: ".";
        width: 3px;
        height: 10px;
        margin-right: 8px;
        background: $color-theme;
      }
    }
  }

  .wrap-body {
    padding: 0 20px 10px 20px;

    .wrap-line {
      margin-top: 30px;
      height: 1px;
      background: #ebedf0;
    }
  }
}
</style>

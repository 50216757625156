<!--轮播组件-->
<template>
	<div :style="[$wrapStyle(styles), $cmpStyle(styles)]">
		<el-image class="img" v-for="(item, index) of imgList" :key="index" mode="widthFix" :style="[getImgStyle()]"
			:src="item.ImageUrl || defaultImage" />
	</div>
</template>
  
<script>
import defaultImage from '@/assets/image/default_otherGoods.jpg'
import { getPromotions } from '@/api/goods'
import { mapGetters } from "vuex";
export default {
	name: "McActivityGroup",
	props: {
		space: {
			type: Object,
			default: () => { }
		},
		radius: {
			type: Object,
			default: () => { }
		},
		list: {
			type: Object,
			default: () => { },
		},
		color: {
			type: Object,
			default: () => { },
		},

	},
	computed: {
		...mapGetters(["project"]),
		styles() {
			return { ...this.space, ...this.radius, ...this.color }
		}
	},
	data() {
		return {
			imgList: [
				{
					imagePath: defaultImage
				}
			],
			defaultImage,
			show: true,
		};
	},

	watch: {
		list: {
			handler(newValue, oldValue) {
				console.log("new", newValue)
				const { type } = newValue
				const _data = {
					shopID: this.project.MartID
				}
				if (type.id == 1) {
					_data.isHome = true
					this.getImgList(_data)
				} else if (type.id == 2 && type.promotionIDs && type.promotionIDs.length > 0) {
					getPromotions(_data).then(res => {
						this.imgList = (res || []).filter(f => type.promotionIDs?.includes(f.PromotionID))
					})
				} else {
					this.imgList = [{
						imagePath: defaultImage
					}]
				}
			},
			immediate: true,
			deep: true,
		}
	},

	methods: {
		getImgList(data) {
			getPromotions(data).then(res => {
				if (res) {
					this.imgList = res?.length ? res : [{
						ImageUrl: defaultImage
					}]
				}
			})
		},
		// 重新加载
		againLoad() {
			console.log(this.mAttrs)
			this.show = false;
			setTimeout(() => {
				this.show = true;
			}, 0);
		},
		getImgStyle() {
			return {
				borderRadius: `${this.radius?.imgRadius}px`
			}
		}
	},
};
</script>
  
<style lang="scss" scoped>
.wrap {
	position: relative;
	z-index: 0;

	.item-img {
		height: 100%;
	}
}
</style>
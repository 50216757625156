<!--图片组件-->
<template>
  <div :style="[$wrapStyle(imageStyle), $cmpStyle(this.imageStyle), { overflow: 'hidden' }]">

    <div class="flex" style="font-size: 16px;height: 200px;" v-if="magic.model == 1">
      <div style="width: 100%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[0].imagePath || defaultImage" />
        </div>
      </div>
    </div>

    <div class="flex" style="font-size: 16px;height: 200px;" v-if="magic.model == 2">
      <div style="width: 40%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[0].imagePath || defaultImage" />
        </div>
      </div>
      <div style="width: 60%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[1].imagePath || defaultImage" />
        </div>
      </div>
    </div>

    <div class="flex" style="font-size: 16px;height: 200px;" v-if="magic.model == 3">
      <div style="width: 40%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[0].imagePath || defaultImage" />
        </div>
      </div>
      <div style="width: 60%;display: flex; flex-direction: column;">
        <div style="height: 50%;">
          <div :style="[getImgWrapStyle()]">
            <el-image class="img" :style="[getImgStyle()]" :src="magic.list[1].imagePath || defaultImage" />
          </div>
        </div>
        <div style="height: 50%;">
          <div :style="[getImgWrapStyle()]">
            <el-image class="img" :style="[getImgStyle()]" :src="magic.list[2].imagePath || defaultImage" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex" style="font-size: 16px;height: 200px;" v-if="magic.model == 4">
      <div style="width: 40%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[0].imagePath || defaultImage" />
        </div>
      </div>
      <div style="width: 60%;display: flex; flex-direction: column;">
        <div style="height: 50%;">
          <div :style="[getImgWrapStyle()]">
            <el-image class="img" :style="[getImgStyle()]" :src="magic.list[1].imagePath || defaultImage" />
          </div>
        </div>
        <div style="display: flex; height: 50%;">
          <div style="width: 50%;">
            <div :style="[getImgWrapStyle()]">
              <el-image class="img" :style="[getImgStyle()]" :src="magic.list[2].imagePath || defaultImage" />
            </div>
          </div>
          <div style="width: 50%;">
            <div :style="[getImgWrapStyle()]">
              <el-image class="img" :style="[getImgStyle()]" :src="magic.list[3].imagePath || defaultImage" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex" style="font-size: 16px;height: 200px;" v-if="magic.model == 7">
      <div style="width: 25%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[0].imagePath || defaultImage" />
        </div>
      </div>
      <div style="width: 25%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[1].imagePath || defaultImage" />
        </div>
      </div>
      <div style="width: 25%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[2].imagePath || defaultImage" />
        </div>
      </div>
      <div style="width: 25%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[3].imagePath || defaultImage" />
        </div>
      </div>
    </div>

    <div class="flex" style="font-size: 16px;height: 200px;" v-if="magic.model == 8">
      <div style="width: 50%; display: flex; flex-direction: column;">
        <div style="height: 50%;">
          <div :style="[getImgWrapStyle()]">
            <el-image class="img" :style="[getImgStyle()]" :src="magic.list[0].imagePath || defaultImage" />
          </div>
        </div>
        <div style="height: 50%;">
          <div :style="[getImgWrapStyle()]">
            <el-image class="img" :style="[getImgStyle()]" :src="magic.list[2].imagePath || defaultImage" />
          </div>
        </div>
      </div>
      <div style="width: 50%;display: flex; flex-direction: column;">
        <div style="height: 50%;">
          <div :style="[getImgWrapStyle()]">
            <el-image class="img" :style="[getImgStyle()]" :src="magic.list[1].imagePath || defaultImage" />
          </div>
        </div>
        <div style="height: 50%;">
          <div :style="[getImgWrapStyle()]">
            <el-image class="img" :style="[getImgStyle()]" :src="magic.list[3].imagePath || defaultImage" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex" style="font-size: 16px;height: 200px;" v-if="magic.model == 5">
      <div style="width: 50%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[0].imagePath || defaultImage" />
        </div>
      </div>
      <div style="width: 50%;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[1].imagePath || defaultImage" />
        </div>
      </div>
    </div>

    <div class="flex" style="font-size: 16px;height: 200px;" v-if="magic.model == 6">
      <div style="flex: 1;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[0].imagePath || defaultImage" />
        </div>
      </div>
      <div style="flex: 1;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[1].imagePath || defaultImage" />
        </div>
      </div>
      <div style="flex: 1;">
        <div :style="[getImgWrapStyle()]">
          <el-image class="img" :style="[getImgStyle()]" :src="magic.list[2].imagePath || defaultImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultValue from '@/assets/image/default_picture.png'
export default {
  name: "McMagic",

  props: {
    magic: {
      type: Object,
      default: () => { },
    },
    space: {
      type: Object,
      default: () => { },
    },
    radius: {
      type: Object,
      default: () => { },
    },
    imageValue: {
      type: Object,
      default: () => { },
    },
    color: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      defaultImage: defaultValue,
    };
  },
  computed: {
    imageStyle() {
      return { ...this.space, ...this.radius, ...this.color }
    },
  },
  methods: {
    getImgWrapStyle() {
      return {
        padding: `${this.space.imgPadding}px`,
        height: "100%"
      }

    },
    getImgStyle() {
      return {
        borderRadius: `${this.radius?.imgRadius}px`
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
}
</style>

<template>
    <el-dialog title="热区编辑器" class="style-select-dia" :visible.sync="show" top="10vh" width="810px">
        <div class="hot-content">
            <div class="hot-top">
                <p class="step">1</p>
                <p>添加热区</p>
                <p class="step">2</p>
                <p>调整热区大小及位置</p>
                <p class="step">3</p>
                <p>设置热区链接</p>
                <p class="step">4</p>
                <p>保存设置</p>
            </div>
            <div class="hot-co" @mousemove="onMouseMove" @mouseup="handleMouseUp">
                <div style="position: relative; min-height: 336px;" class="img-wrap">
                    <img alt="" style="width: 100%;" :src="url || defaultValue" ref="imgRef">
                    <div v-for="item of hotList" :id="item.id" :key="item.id" class="hot_item selected"
                        @mousedown.stop="moveStart($event, item)" :style="getItemStyle(item)">
                        <div class="setting" @dblclick.stop="handleDbClick(item)"
                            style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;">
                            {{ item.jump?.name || "双击添加链接" }}
                        </div>
                        <div class="coor" @mousedown.stop="handleMoveStart($event, item)">
                        </div>
                        <div class="btn-edit-del" @click.stop="handleDelete(item.id)">
                            <i class="el-icon-close"></i>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer ">
            <el-button type="primary" round style="width: 140px" @click="addHot">添加热区</el-button>
            <el-button round style="width: 140px" @click="handleSubmit">保存</el-button>
        </div>

        <JumpDialog ref='jump' @ok="handleOk" :modal="false"></JumpDialog>
    </el-dialog>
</template>

<script>

import defaultValue from '@/assets/image/default_banner.png'
export default {
    name: "setHotDia",
    props: {
        list: {
            type: Array,
            default: () => []
        },
        url: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            editId: '',
            defaultValue,
            startX: 0,
            startY: 0,
            startWidth: 0,
            startHeight: 0,
            left: 0,
            top: 0,
            offX: 0,
            offY: 0,
            disX: 0,
            disY: 0,
            resizing: false,
            move: false,
            show: false,
            currentValue: undefined,
            titleList: [
                {
                    url: require('@/assets/image/store_style/style_1.png'),
                    id: 1,
                },
                {
                    url: require('@/assets/image/store_style/style_2.png'),
                    id: 2,
                },
            ],
            hotList: [
                // {
                //     id: this.$getRandomCode(6),
                //     width: 100,
                //     height: 100,
                //     index: 1,
                //     top: 112,
                //     left: 254,
                // }
            ],
        };
    },
    methods: {
        handleDelete(id) {
            const _index = this.hotList.findIndex(f => f.id == id)
            this.hotList.splice(_index, 1)
        },
        handleOk(val) {
            this.hotList.forEach(f => {
                if (f.id == this.editId) {
                    f.jump = { ...val }
                }
            })
        },
        handleDbClick(item) {
            this.editId = item.id
            const _mValue = item.jump || {}
            this.$nextTick(() => {
                this.$refs.jump.open(_mValue)
            })
        },
        getItemStyle(item) {
            return {
                position: 'absolute',
                top: `${item.top}px`,
                left: `${item.left}px`,
                zIndex: item.index,
                width: `${item.width}px`,
                height: `${item.height}px`,
                cursor: 'move'
            }
        },
        handleMouseUp() {
            this.resizing = false
            this.move = false
        },
        onMouseMove(event) {

            const newLeft = this.left + event.clientX - this.disX
            const newTop = this.top + event.clientY - this.disY

            let _left = newLeft > 0 ? newLeft : 0
            let _top = newTop > 0 ? newTop : 0

            if (_top + this.startHeight >= this.conHeight) {
                _top = this.conHeight - this.startHeight
            }

            if (_left + this.startWidth >= 750) {
                _left = 750 - this.startWidth
            }

            const _width = event.clientX - this.startX + this.startWidth
            const _height = event.clientY - this.startY + this.startHeight

            let newWidth = _width > 30 ? _width : 30;
            let newHeight = _height > 30 ? _height : 30;

            this.hotList.forEach(f => {
                if (f.id == this.currentValue) {
                    if (this.resizing) {
                        f.height = newHeight
                        f.width = newWidth
                    }
                    console.log(this.move, this.resizing)
                    if (this.move) {
                        f.left = _left
                        f.top = _top
                    }
                }
            })
        },
        handleMoveStart(evt, item) {
            this.startX = evt.clientX;
            this.startY = evt.clientY;
            this.startWidth = item.width;
            this.startHeight = item.height;
            this.currentValue = item.id
            this.resizing = true
        },
        moveStart(evt, item) {
            this.currentValue = item.id
            this.startWidth = item.width;
            this.startHeight = item.height;
            this.left = item.left
            this.top = item.top
            this.disX = evt.clientX
            this.disY = evt.clientY

            this.move = true
            this.conHeight = document.querySelector('.hot-content .img-wrap').clientHeight
            // console.log(document.querySelector('.hot-content .img-wrap'))
            // console.log(evt);
        },
        addHot() {
            const _index = this.hotList?.length
            const _item = {
                id: this.$getRandomCode(6),
                width: 200,
                height: 200,
                index: _index + 1,
                top: 122,
                left: 254,
                jump: {}
            }
            this.hotList?.push(_item)
        },
        handleSubmit() {
            const ev = this.hotList.every(e => e.jump?.type)
            if (!ev) {
                this.$message({
                    type: "warning",
                    message: "请选择跳转链接~~",
                });
                return false
            }
            this.$emit('change', this.hotList)
            this.show = false
        },
        open(val) {
            this.show = true;
            this.$nextTick(() => {
                const scrollContainer = document.querySelector('.el-dialog__body')
                scrollContainer.scrollTop = 0
                this.currentValue = val
            })
        },
    },
    watch: {
        list: {
            immediate: true,
            deep: true,
            handler(val) {
                this.hotList = this.$cloneDeep(val)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog__body) {
    padding: 16px 20px 0 16px;
    user-select: none;
}

.hot_item {
    background: #fee69c;
    opacity: .8;
    border: 1px solid var(--primary, #fb6638);
    color: var(--primary, #fb6638);
    display: flex;
    align-items: center;
    justify-content: center;

    .setting {
        max-height: 100%;
        overflow: hidden;
    }

    &:hover {
        .btn-edit-del {
            display: block;
        }
    }

    .btn-edit-del {
        height: 16px;
        width: 16px;
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        z-index: 11;
        cursor: pointer;
        font-size: 14px;
        color: #fff;
        background: var(--primary, #fb6638);
        border-radius: 0 0 0 100%;

        .el-icon-close {
            font-size: 12px;
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
            text-align: right;
            line-height: 1.4;
        }
    }

    .coor {
        width: 10px;
        height: 10px;
        overflow: hidden;
        cursor: se-resize;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 11;
        background-color: var(--primary, #fb6638);
    }
}

.hot-top {
    display: flex;
    justify-content: center;
    margin: 14px 0 30px;

    p {
        line-height: 26px;
    }

    .step {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #fff6f0;
        color: var(--primary, #fb6638);
        text-align: center;
        margin: 0 10px;
    }
}

.hot-co {
    min-height: 336px;
    max-height: 465px;
    background: #f4f5f9;
    border: 1px solid #e5e5e5;
    margin: 0 8px 0;
    text-align: center;
    overflow: auto;
    position: relative;
}

.preview {
    overflow: auto;
}

.preview-content {
    min-height: 400px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    .content_des {
        display: flex;
        justify-content: center;
        padding: 6px 0;
    }

    .content_item {
        width: 46%;
        // width: 240px;
        margin-right: 20px;
        margin-bottom: 10px;

        .img-col {
            position: relative;
            overflow: hidden;
            border-radius: 6px;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        .select-mark {
            position: absolute;
            right: -17px;
            top: -7px;
            width: 46px;
            height: 26px;
            background: #13ce66;
            text-align: center;
            transform: rotate(45deg);
            box-shadow: 0 1px 1px #ccc;
            z-index: 10;

            >i {
                font-size: 12px;
                margin-top: 12px;
                transform: rotate(-45deg);
                color: #fff;
            }

        }
    }
}

:deep(.el-dialog__body) {
    max-height: 620px;
    overflow: auto;
}
</style>
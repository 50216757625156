<!--图片组件-->
<template>
  <div :style="[$wrapStyle(imageStyle), $cmpStyle(this.imageStyle), getFlexStyle()]" class="flex">
    <div class="img-text">{{ imageValue.imgText }}</div>
    <el-image class="img" :style="[getImgStyle()]" :src="(imageValue && imageValue.imagePath) || defaultImage" />
  </div>
</template>

<script>
import defaultValue from '@/assets/image/default_otherGoods.jpg'
export default {
  name: "McImgText",

  props: {
    attrs: {

      type: Object,
      default: () => { },
    },
    space: {
      type: Object,
      default: () => { },
    },
    radius: {
      type: Object,
      default: () => { },
    },
    imageValue: {
      type: Object,
      default: () => { },
    },
    color: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      defaultImage: defaultValue,
    };
  },
  computed: {
    imageStyle() {
      return { ...this.space, ...this.radius, ...this.color }
    },
  },
  methods: {
    getFlexStyle() {
      return {
        flexDirection: this.attrs.textAlign
      }
    },
    getImgStyle() {
      return {
        borderRadius: `${this.radius?.cmpRadius}px`
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
}

.img-text {
  text-align: center;
  height: 60px;
  font-size: 14px;
  color: #969799;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: normal;
}
</style>

<!--标题组件style_1-->
<template>
    <div class="title_com" :style="[mcTitle.wrapStyle()]">
        <div :style="[mcTitle.comStyle()]">
            <div class="top">
                <!-- <i class="el-icon-star-off" style="font-size: 16px; color: mcTitle.color.titleColor;"></i> -->
                <img class="ti_img" src="~@/assets/image/style_select/t2_bg2.png" alt="">
                <div class="title" :style="[mcTitle.titleStyle()]">
                    {{ mcTitle.value.title || '' }}
                </div>
                <!-- <i class="el-icon-star-off" style="font-size: 16px; color: mcTitle.color.titleColor;"></i> -->
                <img class="ti_img" src="~@/assets/image/style_select/t2_bg3.png" alt=""
                    style="transform: rotateY(180deg);">
                <img class="ti_img_bg" src="~@/assets/image/style_select/t2_bg1.png" alt=""
                    style="transform: rotateY(180deg);">
            </div>
            <div class="bottom">
                <div class="info" :style="[mcTitle.subTitleStyle()]">{{ mcTitle.value.info || '' }}</div>
            </div>
            <!--  -->
        </div>
    </div>
</template>


<script>
export default {
    name: 'TitleStyle_10',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title_com {
    box-sizing: content-box;

    .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;

        .info {
            letter-spacing: 7px;
        }
    }

    .top {
        display: flex;
        // align-items: flex-end;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        z-index: 1;

        .ti_img_bg {
            position: absolute;
            width: 99px;
            height: auto;
            bottom: 0;
            z-index: -1;
        }

        .title {
            padding: 0 10px;
        }


        .ti_img {
            width: 20px;
        }
    }

    .title-mid-model {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>

<!--图片组件-->
<template>
  <div :style="[$wrapStyle(imageStyle), $cmpStyle(this.imageStyle)]">
    <el-image class="img" :style="[getImgStyle()]" :src="(imageValue && imageValue.imagePath) || defaultImage" />
    <!-- <video :src="imageValue.videoPath.url" :style="[setVideoStyle()]"></video>
    <span>{{ imageValue.videoPath.url }}</span> -->
  </div>
</template>

<script>
import defaultValue from '@/assets/image/default_otherGoods.jpg'
export default {
  name: "McVideo",

  props: {
    space: {
      type: Object,
      default: () => { },
    },
    radius: {
      type: Object,
      default: () => { },
    },
    imageValue: {
      type: Object,
      default: () => { },
    },
    color: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      defaultImage: defaultValue,
    };
  },
  computed: {
    imageStyle() {
      console.log(this.imageValue);
      return { ...this.space, ...this.color }
    },
  },
  methods: {
    // setVideoStyle() {
    //   return {
    //     width: "100%",
    //     height: this.imageValue.height ? `${this.imageValue.height}px` : '100%'
    //   }
    // },
    getImgStyle() {
      return {
        borderRadius: `${this.radius?.cmpUpperRadius}px ${this.radius?.cmpUpperRadius}px ${this.radius?.cmpLowerRadius}px ${this.radius?.cmpLowerRadius}px`,
        height: this.imageValue.height ? `${this.imageValue.height}px` : '100%'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
}
</style>

import { render, staticRenderFns } from "./GoodsTabs.vue?vue&type=template&id=4bc102dc&scoped=true&"
import script from "./GoodsTabs.vue?vue&type=script&lang=js&"
export * from "./GoodsTabs.vue?vue&type=script&lang=js&"
import style0 from "./GoodsTabs.vue?vue&type=style&index=0&id=4bc102dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_vue-template-compiler@2.7.8_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc102dc",
  null
  
)

export default component.exports
<!--属性组件配置模板-->
<template>
  <ul>
    <li v-for="(s, key, index) in schema" :key="index">
      <component :key="index" :is="typeToComponent[s.type]" v-model="value[key]" v-bind="s" :schema="s">
        <custom-schema-template v-if="s.child" :schema="s.child" :value="value[key]"></custom-schema-template>
      </component>
    </li>
  </ul>
</template>

<script>
import typeToComponent from '@/config/schema-template'

export default {
  name: 'custom-schema-template',

  provide() {
    return {
      mode: this
    }
  },

  props: {
    schema: {
      typeof: Object,
      default: () => { }
    },
    value: {
      typeof: Object,
      default: () => { }
    }
  },
  // computed: {
  //   showComponent() {
  //     console.log('1', this.value,)
  //     console.log('2', this.schema)
  //     return true
  //   }
  // },
  // methods: {
  //   getShowGoods(type) {
  //     return true
  //   }
  // },
  watch: {
    "value.type.productType": {
      handler(val) {
        if (val && val.id == 8) {
          this.showGoods = true
        } else {
          this.showGoods = false
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      typeToComponent,
      showGoods: true
    }
  }
}
</script>

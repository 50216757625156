<!-- 搜索组件-->
<template>
  <div class="search" :style="[$cmpStyle(color), $wrapStyle(space)]">
    <div class="search-body" :style="[getSourceStyle()]">
      <i class="icon el-icon-search" v-if="styles.hasSearch"></i>
      <span class="search-text" :style="[getTextStyle()]">{{ value.text }}</span>
      <i class="icon el-icon-full-screen" v-if="styles.hasScan"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "McSearch",

  props: {
    space: {
      type: Object,
      default: () => { },
    },
    styles: {
      type: Object,
      default: () => { },
    },
    color: {
      type: Object,
      default: () => { },
    },
    value: {
      type: Object,
      default: () => { },
    },
  },

  methods: {
    getTextStyle() {
      if (this.styles.location == "center") {
        return {
          'textAlign': 'center'
        }
      }
    },
    // 搜索框样式
    getSourceStyle() {
      let temp = {};
      if (this.styles.shape == "circular") temp.borderRadius = "25px";
      temp.justifyContent = 'space-between'
      // if (this.styles.location == "center") temp.justifyContent = "center";
      return temp;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  padding: 8px 12px;

  .search-body {
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    height: 34px;
    background: #fff;
    font-size: 14px;
    color: #969799;

    .search-text {
      flex: 1;
    }
  }
}
</style
>+

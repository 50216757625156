<!--轮播组件-->
<template>
  <div :style="[$wrapStyle(styles), $cmpStyle({ ...styles, ...color })]">
    <el-carousel :height="`${styles.componentHeight}px`" v-bind="mAttrs" :type="mAttrs.type"
      :direction="mAttrs.direction">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <el-image ref="swiper-item" class="item-img" :src="item.image || defaultImage"
          :style="{ borderRadius: styles.cmpRadius + 'px' }"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
// import baseSwiper from "./baseSwiper";
import defaultImage from '@/assets/image/default_otherGoods.jpg'
export default {
  name: "McSwiper",

  // components: {
  //   baseSwiper,
  // },

  props: {
    styles: {
      type: Object,
      default: () => { },
    },
    attrs: {
      type: Object,
      default: () => { },
    },
    list: {
      type: Array,
      default: () => [],
    },
    color: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      defaultImage,
      show: true,
      mAttrs: {},
      modelObj: {
        basis: {
          crown: false,
          imgWidth: 100,
          previousMargin: 0,
          nextMargin: 0,
        },
        card: {
          crown: true,
          imgWidth: 100,
          previousMargin: 100,
          nextMargin: 100,
        },
        line: {
          crown: false,
          imgWidth: 97,
          previousMargin: 20,
          nextMargin: 0,
        },
      },
    };
  },

  watch: {
    attrs: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.mAttrs = {
          ...this.attrs,
          ...this.modelObj[newValue.model],
          direction: newValue.model == 'line' ? 'vertical' : "horizontal",
          type: newValue.model == 'card' ? 'card' : ''
        };
        this.againLoad();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    // 重新加载
    againLoad() {
      console.log(this.mAttrs)
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 0);
    },

  },
};
</script>

<style lang="scss" scoped>
:deep(.el-image) {
  width: 100%;
  height: 100%;
}

.wrap {
  position: relative;
  z-index: 0;

}
</style>
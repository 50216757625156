
<template>
  <config-item :label='label'>
    <div class="flex row-right items-center" style="width: calc(100% - 24px);">
      <el-color-picker v-model="mValue" :predefine="predefineColors" v-bind="mOptions" show-alpha></el-color-picker>
      <el-tag class="ml12" style="width: 100px;">{{ mValue || 'transparent' }}</el-tag>
    </div>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
// import ConfigColorPicker from "./ConfigColorPicker.vue";

export default {
  name: "SchemaColor",

  mixins: [schemaMixin],

  // components: {
  //   ConfigColorPicker,
  // },

  data() {
    return {
      predefineColors: [
        "#ffffff",
        "#f5f5f5f5",
        "#F2F2F2",
        "#FF4444",
        "#FFCD00",
        "#3FBC87",
        "#5197FF",
        "#BADCFF",
        "#000000",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.el-color-picker {
  display: flex;
  align-items: center;
}

:deep(.el-color-picker__trigger) {
  width: 56px;
  height: 26px;
}
</style>
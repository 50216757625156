<!--tab组件-->
<template>
  <div class="wrap" :style="[$wrapStyle({ ...this.space, ...this.radius, ...this.color })]">
    <div class="wrap-body" :style="[getWrapStyle()]">
      <ul class="tabs" :style="[getTabsStyle()]">
        <li v-for="(item, index) in tabList" :key="`${item.id}_${index}`" class="tab-item" :style="[geItemStyle()]">
          <el-image v-if="['image', 'image-text'].includes(attrs.type)" mode="widthFix" :style="[geItemImgStyle()]"
            :src="item.image || defaultImage" fit="cover" />
          <span v-if="['text', 'image-text'].includes(attrs.type)" :style="[getTextStyle()]" class="ellipsis-1">{{
            item.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/image/default_picture.png'
export default {
  name: "McTab",

  props: {
    space: {
      type: Object,
      default: () => { },
    },
    radius: {
      type: Object,
      default: () => { },
    },
    color: {
      type: Object,
      default: () => { },
    },
    size: {
      type: Object,
      default: () => { },
    },
    attrs: {
      type: Object,
      default: () => { },
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fixed: true,
      defaultImage,
    };
  },

  computed: {
    itemWidth() {
      return ((375 - this.space.pageMargin * 2 - (this.space.imgPadding || 0)) / (this.attrs.max > 5 ? 5 : this.attrs.max));
    },
  },

  methods: {

    getTextStyle() {
      return {
        fontSize: this.$unit(this.size.fontSize)
      }
    },
    // 容器样式
    getWrapStyle() {
      return {
        overflowX: this.attrs.model == "fixed" ? "hidden" : "auto",
        ...this.$cmpStyle({ ...this.space, ...this.radius, ...this.color }),
        // paddingLeft: `${this.space.pagePadding}px`,
        // paddingRight: `${this.space.pagePadding}px`
      };
    },

    // tabs 样式
    getTabsStyle() {
      return {
        width: this.attrs.model == "fixed" ? '100%' : this.$unit(
          this.itemWidth * this.tabList.length + (this.space.imgPadding || 0)
        ),
        padding: `0 ${this.$unit(this.space.imgPadding / 2)} 8px ${this.$unit(
          this.space.imgPadding / 2
        )}`,
      };
    },

    // 单项样式
    geItemStyle() {
      return {
        width: this.$unit(this.itemWidth),
        padding: this.$unit(this.space.imgPadding),
        color: this.color.titleColor,
      };
    },

    // 单项图片样式
    geItemImgStyle() {
      return {
        width: `${this.space.imgWidth}%`,
        height: '66%',
        borderRadius: this.$unit(this.radius.imgRadius),
        marginBottom: this.attrs.type == "image-text" ? "5px" : 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  .wrap-body {
    &::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }

    .tabs {
      display: flex;
      flex-wrap: wrap;

      .tab-item {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>


<template>
  <el-dialog title="选择商品" :visible.sync="visible" :close-on-click-modal='false' :append-to-body='true' width="45%" top="10vh">
    <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" height="60vh"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" align='center' header-align='center'>
      </el-table-column>
      <el-table-column prop="name" label="商品名" align='center' header-align='center'>
      </el-table-column>
      <el-table-column prop="unit" label="商品规格" align='center' header-align='center'></el-table-column>
      <el-table-column label="商品封面" align='center' header-align='center'>
        <template slot-scope="scope">
          <img class="w50 h50" :src="scope.row.cover">
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格（元）" align='center' header-align='center'>
      </el-table-column>
      <el-table-column prop="originalPrice" label="原价（元）" align='center' header-align='center'>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <div class="dia-footer">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
          :page-sizes="[30, 50, 100, 200]" :page-size="30" layout="total, sizes, prev, pager, next" :total="total">
        </el-pagination>
        <div class="btn-wrap">
          <el-button @click="visible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="submit" size="small">确 定</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getGoodsList } from "@/api/goods";

export default {
  name: "GoodsConfigChoose",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    this.getList();
  },

  data() {
    return {
      total: 0,
      pageSize: 30,
      pageIndex: 1,
      visible: false,
      selectList: [],
      list: [],
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        this.visible = this.show;
      },
    },
    visible: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit("update:show", newValue);
      },
    },
  },

  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getList()
    },
    // 获取商城商品列表
    async getList() {
      const { List, TotalCount } = await getGoodsList({shopID:this.project.MartID, projectId: this.project.id, pageSize: this.pageSize, pageIndex: this.pageIndex });
      // if (IsSuccess) {
      // 筛选字段
      this.list = List;
      this.total = TotalCount
      // 根据选中列表id，筛选匹配对应数组，用于勾选回显
      this.selectList = this.list.filter((item) => {
        return this.value.includes(item.id);
      });

      // 勾选回显回调方法
      setTimeout(() => {
        this.toggleSelection(this.selectList);
      }, 0);
      // }
    },

    // 勾选回显
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable?.toggleRowSelection(row);
        });
      }
    },

    // 勾选选中
    handleSelectionChange(val) {
      this.selectList = val;
    },

    // 提交
    submit() {
      this.$emit(
        "submit",
        this.selectList.map((item) => item.id)
      );
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dia-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .el-pagination {
    flex: 1;
    text-align: left;
  }

  .btn-wrap {
    width: 260px;
  }
}

:deep(.el-dialog) {
  .el-dialog__title {
    font-size: 14px !important;
    font-weight: 600;
  }

  .el-dialog__body {
    padding: 10px 20px 30px 20px !important;
  }
}

.wrap {
  background: #f2f2f6;
  height: 400px;
  border-radius: 5px;
}
</style>
import axios from 'axios'
import store from '@/store'
// import global from '@/config/global'
import { Notification } from 'element-ui';

// create an axios instance
console.log('VUE_APP_API_URL',process.env.VUE_APP_API_URL)
console.log('VUE_APP_BASE_API',process.env.VUE_APP_BASE_API)
const request = axios.create({

  // baseURL: global.baseApi, // url = base url + request url
  // baseURL: process.env.NODE_ENV == 'development' ? '/api' : global.baseApi, // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
})

// 请求拦截
request.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers['Authorization'] = store.getters.token
  }
  return config
})

// 响应拦截
request.interceptors.response.use(response => {
  const token = response.headers['right-token']
  token && store.commit('setToken', token)

  return response.data
}, (error) => {
  console.log(error.response)
  const _data = error?.response.data
  Notification.error({
    title: '错误',
    message: _data.message || _data.Message || _data.ErrorMessage || '网络错误，请稍后再试！'
  })
})

export default request

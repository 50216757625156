<!--标题组件style_1-->
<template>
    <div class="title_com" :style="[mcTitle.wrapStyle()]">
        <div class="title-left-model" :style="[mcTitle.comStyle()]">
            <img class="ti_img" src="~@/assets/image/style_select/title3.png" alt="">
            <!-- <img class="ti_img" src="" alt="~@/assets/image/style_select/title3.png"> -->
            <div class="ti-right">
                <div class="title" :style="[mcTitle.titleStyle()]">
                    {{ mcTitle.value.title || '' }}
                </div>
                <div class="info" :style="[mcTitle.subTitleStyle()]">{{ mcTitle.value.info || '' }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleStyle_14',
    inject: ["mcTitle"],
}
</script>

<style lang="scss" scoped>
.title_com {
    box-sizing: content-box;

    .ti_img {
        margin-left: 12px;
        max-width: 40px;
        max-height: 40px;
    }

    .ti-right {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        justify-content: space-between;

        .title {
            font-size: 16px !important;
        }
    }

    .title-left-model {
        display: flex;
        // align-items: flex-end;
        padding-left: 10px;
        padding-right: 10px;
    }

    .title-mid-model {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>
